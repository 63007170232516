<template>
<div v-if="!loading"  class="flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
            <div class="lg:flex lg:h-full lg:flex-col">
            <header class="flex items-center justify-between border-b border-gray-200 py-4">
            <div class="flex flex-row items-center gap-5 w-full justify-between sm:justify-end  ">
                <!-- Change month -->
                <div class="flex items-stretch rounded-md shadow-sm">
                    <button @click="changeMonth('subtract')" type="button" class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Previous month</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button type="button" class="w-22 border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative">{{currentMonth.monthName}}</button>
                    <button @click="changeMonth('add')" type="button" class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Next month</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>

                <!-- Change year -->
                <div class="flex items-stretch rounded-md shadow-sm">
                    <button @click="changeYear('subtract')" type="button" class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Previous month</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button type="button" class="w-14 border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative">{{currentMonth.year}}</button>
                    <button @click="changeYear('add')" type="button" class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:hover:bg-gray-50">
                        <span class="sr-only">Next month</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
            </header>
            <div class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div class="bg-white py-2">Pon</div>
                    <div class="bg-white py-2">Wt</div>
                    <div class="bg-white py-2">Śr</div>
                    <div class="bg-white py-2">Czw</div>
                    <div class="bg-white py-2">Pt</div>
                    <div class="bg-white py-2">Sob</div>
                    <div class="bg-white py-2">Nie</div>
                </div>
                <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <!-- above large screen width -->
                    <div class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 gap-px border-b border-gray-200">
                        <button @click="selectedDay === day ? selectedDay = null : selectedDay = day" v-for="day in days" :key="day.date" class="flex flex-col items-between gap-0.5 hover:bg-gray-50" :class="[day.monthName !== currentMonth.monthName ? 'bg-gray-100' : 'bg-white' , 'relative py-2 px-3', selectedDay && (selectedDay.fullDate === day.fullDate) ? 'bg-blue-50 hover:bg-blue-50' : undefined]">
                            <time :datetime="day.fullDate" :class="day.fullDate === currentDay ? 'flex h-6 w-6 items-center justify-center rounded-full bg-purple-600 font-bold text-white' : undefined" class="font-semibold">{{day.day}}</time>
                            <div class="text-xs text-gray-500 flex items-center" v-if="day.events.undone.length > 0"><ClockIcon class="w-4 mr-2 text-yellow-500" ></ClockIcon> Realizacja:  {{day.events.undone.length }}</div>
                            <div class="text-xs text-gray-500 flex items-center" v-if="day.events.done.length > 0"><CheckIcon class="w-4 mr-2 text-green-600" ></CheckIcon> Zrealizowane: {{day.events.done.length }}</div>
                            <div class="text-xs text-gray-500 flex items-center" v-if="day.events.other.length > 0"><BellIcon class="w-4 mr-2 text-blue-700" ></BellIcon> Zdarzenia: {{day.events.other.length }}</div>
                        </button>
                    </div>

                    <!-- below large screen width -->
                    <div class="isolate grid w-full grid-cols-7 grid-rows-5 gap-px border-b border-gray-200 lg:hidden">
                        <button @click="selectedDay === day ? selectedDay = null : selectedDay = day" v-for="day in days" :key="day.date" type="button" :class="[day.monthName !== currentMonth.monthName ? 'bg-gray-100' : 'bg-white', selectedDay && (selectedDay.fullDate === day.fullDate) ? 'bg-blue-50 hover:bg-blue-50' : undefined ,'flex h-17 flex-col py-2 px-3 hover:bg-gray-50 focus:z-10 font-medium']">
                            <time :datetime="day.date" :class="[ day.fullDate === currentDay ? ' text-purple-500' : undefined ]" >{{day.day}}</time>
                            <span class="sr-only">{{ day.events.length }} events</span>
                            <div class="flex">
                              <div class="text-xs text-gray-500 flex items-center" v-if="day.events.undone.length > 0"><ClockIcon class="w-3 text-yellow-500" ></ClockIcon> {{day.events.undone.length }}</div>
                              <div class="text-xs text-gray-500 flex items-center" v-if="day.events.done.length > 0"><CheckIcon class="w-3 text-green-600" ></CheckIcon> {{day.events.done.length }}</div>
                                <!-- <span v-if="day.events.length > 0" class="rounded-full px-2 bg-indigo-400">{{day.events.length}}</span> -->
                            </div>
                            <div>
                              <div class="text-xs text-gray-500 flex items-center" v-if="day.events.other.length > 0"><BellIcon class="w-3 text-blue-700" ></BellIcon> {{day.events.other.length }}</div>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Selected day details -->
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 -translate-y-5" enter-to-class="transform opacity-100 translate-y-0" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 translate-y-0" leave-to-class="transform opacity-0 -translate-y-0">
                <div v-if="selectedDay" class="p-6 bg-white">
                    <div class="flex items-center">
                        <div class="flex-auto">
                            <h1 class="text-md leading-6 text-gray-700"><span class="font-semibold">{{ selectedDay.day }} {{ selectedDay.monthName }} {{selectedDay.year}} </span>
                            <button @click="selectedDay = null " type="button" class="inline-flex items-center rounded-full p-2 text-gray-400 hover:text-gray-500"></button>
                            </h1>
                            <!-- <p class="mt-4 text-md text-gray-700 font-semibold text-xs">Wszystkie: <span class="font-semibold">{{ selectedDay.events.done.length + selectedDay.events.undone.length }}</span></p> -->
                            <p class="mt-4 text-md text-yellow-500 font-semibold text-xs">Realizacja: <span class="font-semibold text-gray-700">{{ selectedDay.events.undone.length }}</span></p>
                            <div class="grid grid-cols-3 gap-4 sm:grid-cols-3 mt-2">
                              <div v-for="order in selectedDay.events.undone" :key="order.id" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                                <div class="min-w-0 flex-1">
                                  <!-- {{order.id}} -->
                                  <div class="mb-1">
                                      <div v-for="status in statuses" :key="status" :class="[order.status === status.id ? '' : 'hidden']">
                                          <span v-if="order.status === status.id" :class="[`inline-flex items-center rounded-md bg-${status.bg} px-1.5 py-0.5 text-xs font-medium text-${status.text} ring-1 ring-inset ring-${status.ring}`]">{{status.name}}</span>
                                      </div>
                                  </div>
                                  <div class="focus:outline-none cursor-pointer" @click.prevent="this.$router.push(`/zamowienie?id=${order.id}`)">
                                    <span class="absolute inset-0" aria-hidden="true" />
                                    <p class="text-sm font-medium text-gray-900">{{order.type.toUpperCase()}} {{ order.client.name }} </p>
                                    <p class="truncate text-xs text-gray-500">{{moment(order.client.deliveryDateTimestamp).format("DD.MM.YYYY HH:mm")}} - Przez {{order.created.user}} </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="mt-4 text-md text-green-600 font-semibold text-xs">Zrealizowane: <span class="font-semibold text-gray-700">{{ selectedDay.events.done.length }}</span></p>
                            <div class="grid grid-cols-3 gap-4 sm:grid-cols-3 mt-2">
                              <div v-for="order in selectedDay.events.done" :key="order.id" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                                <div class="min-w-0 flex-1">
                                  <div class="mb-1">
                                      <div v-for="status in statuses" :key="status" :class="[order.status === status.id ? '' : 'hidden']">
                                          <span v-if="order.status === status.id" :class="[`inline-flex items-center rounded-md bg-${status.bg} px-1.5 py-0.5 text-xs font-medium text-${status.text} ring-1 ring-inset ring-${status.ring}`]">{{status.name}}</span>
                                      </div>
                                  </div>
                                  <div class="focus:outline-none cursor-pointer" @click.prevent="this.$router.push(`/zamowienie?id=${order.id}`)">
                                    <span class="absolute inset-0" aria-hidden="true" />
                                    <p class="text-sm font-medium text-gray-900">{{order.type.toUpperCase()}} {{ order.client.name }} </p>
                                    <p class="truncate text-xs text-gray-500">{{moment(order.client.deliveryDateTimestamp).format("DD.MM.YYYY HH:mm")}} - Przez {{order.created.user}} </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="mt-4 mb-2 text-md text-blue-600 font-semibold text-xs">Zdarzenia dodatkowe: <span class="font-semibold text-gray-700">{{ selectedDay.events.other.length }}</span></p>
                            <div v-for="ev in selectedDay.events.other" :key="ev" :id="ev.id">
                              <div class="text-xs mb-2 border border-gray-300 px-4 py-3 rounded-md">
                                <div class="flex items-center"><span class="text-gray-700">[{{ev.time}} dla {{ev.person}}]</span> <span class="text-white bg-red-500 border border-red-600 hover:bg-red-600 transition ease-in-out duration-300 px-2 py-0.5 rounded-md ml-auto cursor-pointer" @click.prevent="markEvForDelete(ev.id)">Usuń</span></div>
                                <div><span class="text-sm font-semibold">{{ev.title}}</span></div>
                                <div class="mt-0.5">
                                  {{ev.description}}
                                </div>
                                <div v-if="ev.guests !== undefined && ev.guests > 0 " class="mt-1.5 flex w-full items-center flex-none gap-x-2">
                                  <dt class="flex-none">
                                  <UserGroupIcon :class="['h-4 w-4', 'text-yellow-500']" aria-hidden="true" />
                                  </dt>
                                  <dd :class="['text-xs',  'text-yellow-500']">
                                  <span>{{ev.guests}} gości</span>
                                  </dd>
                                </div>
                              </div>
                            </div>
                            <div class="relative mt-8">
                              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t border-gray-300" />
                              </div>
                              <div class="relative flex justify-center mt-2">
                                <span class="bg-white px-8 font-medium text-base text-gray-500">Tworzenie zdarzeń</span>
                              </div>
                            </div>
                            <h2 class="text-base font-semibold leading-5 text-gray-900 mt-4">Dodaj nowe zdarzenie</h2>
                            <p class="max-w-2xl text-sm leading-6 text-gray-600">Wypełnij formularz, aby dodać zdarzenie dodatkowe.</p>
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                              <label for="username" class="block text-sm font-medium leading-6 text-gray-900 pt-1.5">Tytuł*</label>
                              <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                  <input type="text" v-model="additionalEvent.title"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Tytuł zdarzenia" />
                                </div>
                              </div>
                            </div>
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                              <label for="username" class="block text-sm font-medium leading-6 text-gray-900 pt-1.5">Osoba*</label>
                              <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                  <input type="text" v-model="additionalEvent.person" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Imię i nazwisko" />
                                </div>
                              </div>
                            </div>
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                              <label for="username" class="block text-sm font-medium leading-6 text-gray-900 pt-1.5">Ilość gości*</label>
                              <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <div :class="['text-sm leading-6',  'text-yellow-500']">
                                    <div class="relative mt-1 w-32 rounded-md shadow-sm">
                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span class="text-gray-500 text-sm"></span>
                                        </div>
                                        <input type="number" name="price" id="price" v-model="additionalEvent.guests" @change="checkGuestsAmount()" class="block w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6" placeholder="0.00" />
                                        <div class="absolute inset-y-0 right-0 flex items-center">
                                            <div id="currency" name="currency" class="h-full flex justify-center items-center rounded-md border-0 bg-transparent py-0 pl-2 pr-3 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm">
                                            os.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                  <input type="text" v-model="additionalEvent.guests" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Imię i nazwisko" />
                                </div> -->
                              </div>
                            </div>
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                              <label for="about" class="block text-sm font-medium leading-6 text-gray-900 pt-1.5">Opis*</label>
                              <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <textarea id="about" name="about" rows="3" v-model="additionalEvent.description" class="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <p class="mt-0.5 ml-1 text-xs leading-6 text-gray-600">Napisz kilka zdań o zdarzeniu.</p>
                              </div>
                            </div>
                            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                              <label for="about" class="block text-sm font-medium leading-6 text-gray-900 pt-1.5">Godzina*</label>
                              <div class="mt-2 sm:col-span-2 sm:mt-0">
                                <input type="time" v-model="additionalEvent.time" class="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                              </div>
                            </div>
                            <div class="flex justify-end">
                              <button type="button" @click.prevent="saveAdditionalEvent()" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Zapisz zdarzenie</button>
                            </div>
                            <div class="flex justify-end text-xs mt-2 text-red-600" v-if="additionalEventError === true">
                              <span>Wypełnij wszystkie wymagane pola, aby kontynuować.</span>
                            </div>
                             
                        </div>

                    </div>
                    <!-- <TableRoutesList v-if="selectedDay.events.length && !loading" :routes="selectedDay.events"></TableRoutesList> -->
                    <!-- <EmptyState class="mt-4" v-else></EmptyState> -->
                </div>
                </transition>
            </div>
        </div>
        </div>
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <askForEventDelete v-if="showAskForEvDelete" @accept="deleteEv()" :modalData="modalDataEvDelete" @closeModal="showAskForEvDelete = false"></askForEventDelete>
</template>

<script>
import moment from 'moment';
import {db} from "@/firebase/gfbconf.js";
import LoadingSpinner from '../components/LoadingSpinnerHub.vue';
import askForEventDelete from '@/components/AlertModal.vue';
import { 
    ChevronDownIcon,
    ChevronLeftIcon, 
    ChevronRightIcon,
    ClockIcon, 
    CheckIcon, 
    BellIcon,
    UserGroupIcon} from '@heroicons/vue/outline'

export default {
   components: {
        ChevronDownIcon,
        ChevronLeftIcon, 
        ChevronRightIcon,
        ClockIcon, 
        CheckIcon, 
        BellIcon, 
        UserGroupIcon,
        LoadingSpinner,
        askForEventDelete
  },
   data() {
      return {
        loading: true,
        moment: moment,
        showAskForEvDelete: false,
        additionalEventError: false,
        additionalEvent: {
          title: "",
          person: "",
          guests: 0,
          description: "",
          time: "12:00",
          day: "",
          unix: 0,
          created: {
            by: "",
            unix: 0
          }
        },
        modalDataEvDelete: {
            headerText: "Usuń zdarzenie",
            bodyText: "Czy na pewno chcesz usunąć to zdarzenie? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        currentMonth: {
            month: 0,
            year: 0,
            monthName: ""
        },
        selectedDay: '',
        days: [],
        statuses: [
            {id: 1,name:'Przyjęto', bg: 'gray-50', text: 'gray-600', ring: 'gray-500', circle: 'gray-300'},
            {id: 2,name:'Do realizacji', bg: 'blue-50', text: 'blue-700', ring: 'blue-700', circle: 'blue-300'},
            {id: 3,name:'Zrealizowane', bg: 'green-50', text: 'green-700', ring: 'green-600', circle: 'green-300'},
            {id: 4,name:'Anulowane', bg: 'red-50', text: 'red-700', ring: 'red-600', circle: 'red-300'},
        ]
      }
   },
   created() {
        moment.locale('pl');
        this.setCurrentMonth()
   },
   beforeUnmount() {

   },
   methods: {
        async deleteEv()
        {
          try{
            await db.collection("AdditionalEvents").doc(this.markedIdForDelete).delete();
            for(let i=0;i<this.selectedDay.events.other.length;i++)
            {
              let crntEv = this.selectedDay.events.other[i];
              if(crntEv.id === this.markedIdForDelete)
              {
                this.selectedDay.events.other.splice(i,1);
              }
            }
            this.$store.commit('setNotification',{
                show: true,
                head: "Sukces",
                subheader: `Usunięto zdarzenie z bazy danych.`,
                success: true
            }); 
          }
          catch(error)
          {
            console.log(error);
            this.$store.commit('setNotification',{
                show: true,
                head: "Coś poszło nie tak..",
                subheader: `Wystąpił nieoczekiwany błąd.`,
                success: false
            }); 
          }
        },
        markEvForDelete(id)
        {
          this.markedIdForDelete = id;
          this.showAskForEvDelete = true;
        },
        checkGuestsAmount(){
            if(this.additionalEvent.guests.length === 0)
            {
                this.additionalEvent.guests = 0;
            }
            if(this.additionalEvent.guests < 0)
            {
                this.additionalEvent.guests = 0;
            }
            else
            {
                this.additionalEvent.guests = parseFloat(this.additionalEvent.guests);
            }

            this.additionalEvent.guests = Math.round(this.additionalEvent.guests)
            this.additionalEvent.guests = parseFloat(this.additionalEvent.guests)
        },
        async saveAdditionalEvent()
        {
          this.additionalEventError = false;
          if(this.additionalEvent.title.length === 0)
          {
            this.additionalEventError = true;
            return
          }
          if(this.additionalEvent.person.length === 0)
          {
            this.additionalEventError = true;
            return
          }
          if(this.additionalEvent.description.length === 0)
          {
            this.additionalEventError = true;
            return
          }
          this.additionalEvent.day = this.selectedDay.fullDate;
          this.additionalEvent.unix = moment(`${this.selectedDay.fullDate} ${this.additionalEvent.time}`, "YYYY-MM-DD HH:mm").unix();
          this.additionalEvent.created.by = this.$store.state.user.uid;
          this.additionalEvent.created.unix = moment().unix();

          try{
            await db.collection("AdditionalEvents").doc().set(this.additionalEvent);
            for(let i=0;i<this.days.length; i++)
            {
              let day = this.days[i];
              day.events.other = [];
            }
            await this.getOtherEvents()
            this.$store.commit('setNotification',{
                show: true,
                head: "Sukces",
                subheader: `Zapisano zdarzenie w bazie danych.`,
                success: true
            }); 
          }
          catch(error)
          {
            console.log(error);
            this.$store.commit('setNotification',{
                show: true,
                head: "Wystąpił błąd",
                subheader: `Zapis do bazy danych nie powiódł się.`,
                success: false
            }); 
          }
          

          this.additionalEvent.title = "";
          this.additionalEvent.person = "";
          this.additionalEvent.description = "";
          this.additionalEvent.time = "12:00";
          this.additionalEvent.guests = 0


        },
        changeYear(type)
        { 
            this.selectedDay = null;
            let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
            if(type === 'add')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").add(12, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").add(12, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").add(12, 'months').format('MMMM');
            }
            if(type === 'subtract')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").subtract(12, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").subtract(12, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").subtract(12, 'months').format('MMMM');
            }
            this.getDaysOfMonth();
            
        },
        changeMonth(type)
        {
            this.selectedDay = null;
            let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
            if(type === 'add')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").add(1, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").add(1, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").add(1, 'months').format('MMMM');
            }
            if(type === 'subtract')
            {
                this.currentMonth.year = moment(current,"DD-M-YYYY").subtract(1, 'months').year();
                this.currentMonth.month = moment(current,"DD-M-YYYY").subtract(1, 'months').month();
                this.currentMonth.monthName = moment(current,"DD-M-YYYY").subtract(1, 'months').format('MMMM');
            }
            
            this.getDaysOfMonth();

            
        },
        async setDay(day)
        {
            if(day !== this.selected)
            {
                this.selected = day;
                // this.getEvents(day)
            }
        },
        async getOtherEvents()
        {
            let start = moment(this.days[0].fullDate, "YYYY-MM-DD").unix();
            let end = moment(this.days[this.days.length-1].fullDate, "YYYY-MM-DD").set({hour: 23, minutes: 59, seconds: 59}).unix();

            const getEvents = await db.collection("AdditionalEvents").where("unix", ">=", start).where("unix", "<=", end).orderBy("unix", "asc").get();

            for(let i=0; i< getEvents.docs.length; i++)
            {
            let event = getEvents.docs[i].data();
            event.id = getEvents.docs[i].id;
            for(let y=0; y< this.days.length; y++)
            {
                let day = this.days[y];
                if(day.fullDate === event.day)
                {
                day.events.other.push(event);
                }
            }
            }

            
            
        },
        setCurrentMonth()
        {
            this.currentMonth.year = moment().year();
            this.currentMonth.month = moment().month();
            this.currentMonth.monthName = moment().format('MMMM');
            this.currentDay = moment().format('YYYY-MM-DD');
            this.getDaysOfMonth();
            let currentDayDate
            for(let i = 0; i < this.days.length; i++) {
                if(this.days[i].fullDate === this.currentDay) {
                currentDayDate = this.days[i]
                }
            }
            this.setDay(currentDayDate)
        },
        async getDaysOfMonth()
        {
            this.days = [];
            let current = `01-${this.currentMonth.month+1}-${this.currentMonth.year}`
            let amountOfDaysInMonth = moment(current,"DD-M-YYYY").daysInMonth();

            let start = moment(`1-${this.currentMonth.month+1}-${this.currentMonth.year}`, 'D-M-YYYY');
            let end = moment(`${amountOfDaysInMonth}-${this.currentMonth.month+1}-${this.currentMonth.year}`, 'D-M-YYYY').set({hours: 23, minutes: 59, seconds: 59});

            let getCarts = await db.collection("Carts").where("client.deliveryDateTimestamp", ">=", start.toDate()).where("client.deliveryDateTimestamp", "<=", end.toDate()).get();

            // Unpack and fix data on carts
            let carts = [];
            for(let i=0;i<getCarts.docs.length;i++)
            {
                let item = getCarts.docs[i].data();
                item.client.deliveryDateTimestamp = moment.unix(item.client.deliveryDateTimestamp.seconds).toDate();
                carts.push(item);
            }


            for(let i=1; i<amountOfDaysInMonth; i++)
            {
                let momentObj = moment(`${i}-${this.currentMonth.month+1}-${this.currentMonth.year}`,"D-M-YYYY")
                let day = {
                    fullDate: momentObj.format("YYYY-MM-DD"), 
                    dayOfWeek: momentObj.day(),
                    year: momentObj.year(),
                    month: momentObj.month(),
                    day: momentObj.date(),
                    monthName: momentObj.format('MMMM'),
                    unix: momentObj.unix(),
                    weekNum: momentObj.week(),
                    events: {
                        created: [],
                        undone: [],
                        done: [],
                        other: []
                    },
                    titleDate: momentObj.format("Do MMMM YYYY")
                }
                for(let y=0; y < carts.length; y++)
                {
                    let item = carts[y];
                    if(moment(item.client.deliveryDateTimestamp).format("DD.MM.YYYY") === momentObj.format("DD.MM.YYYY"))
                    {
                        if(item.status === 2 || item.status === 1)
                        {
                        
                        day.events.undone.push(item);

                        }
                        if(item.status === 3)
                        {
                        day.events.done.push(item);
                        }
                    }
                }

                this.days.push(day);
            }
            this.addRestOfDaysToCallendar();
            
        },
        async addRestOfDaysToCallendar()
        {
            this.loading = true;
            let amountFromStart  = this.days[0].dayOfWeek-7*(-1);
            let amountFromEnd = this.days[this.days.length-1].dayOfWeek;
            let currentDayFromStart = moment(this.days[0].fullDate,"YYYY-MM-DD");
            let currentDayFromEnd = moment(this.days[this.days.length-1].fullDate,"YYYY-MM-DD");
            
            for(let i=0; i<amountFromStart-1; i++)
            {
                let currentDay = currentDayFromStart.subtract(1, 'days')
                let endOfquery = moment(currentDay.unix(),"X").set({hour: 23, minutes: 59, seconds: 59}).toDate();

                let getCarts = await db.collection("Carts").where("client.deliveryDateTimestamp", ">=", currentDay.toDate()).where("client.deliveryDateTimestamp", "<=", endOfquery).get()

                // Unpack and fix data on carts
                let carts = [];
                let undone = [];
                let done = [];
                for(let i=0;i<getCarts.docs.length;i++)
                {
                  let item = getCarts.docs[i].data();
                  item.client.deliveryDateTimestamp = moment.unix(item.client.deliveryDateTimestamp.seconds).toDate();
                  carts.push(item);
                }

              for(let y=0; y < carts.length; y++)
              {
                  let item = carts[y];
                  if(moment(item.client.deliveryDateTimestamp).format("DD.MM.YYYY") === currentDay.format("DD.MM.YYYY"))
                  {
                    if(item.status === 2 || item.status === 1)
                    {
                      
                      undone.push(item);

                    }
                    if(item.status === 3)
                    {
                      done.push(item);
                    }
                  }
              }

                this.days.unshift({
                    fullDate: currentDay.format("YYYY-MM-DD"), 
                    dayOfWeek: currentDay.day(),
                    year: currentDay.year(),
                    month: currentDay.month(),
                    day: currentDay.date(),
                    monthName: currentDay.format('MMMM'),
                    unix: currentDay.unix(),
                    weekNum: currentDay.week(),
                    events: {
                      created: [],
                      undone: undone,
                      done: done,
                      other: []
                    },
                    titleDate: currentDay.format("Do MMMM YYYY")
                })
            }
            
            for(let i=0; i<=6-amountFromEnd; i++)
            {
                let currentDay = currentDayFromEnd.add(1, 'days');
                let endOfquery = moment(currentDay.unix(),"X").set({hour: 23, minutes: 59, seconds: 59}).toDate();


                let getCarts = await db.collection("Carts").where("client.deliveryDateTimestamp", ">=", currentDay.toDate()).where("client.deliveryDateTimestamp", "<=", endOfquery).get()
                // Unpack and fix data on carts
                let carts = [];
                let undone = [];
                let done = [];
                for(let i=0;i<getCarts.docs.length;i++)
                {
                  let item = getCarts.docs[i].data();
                  item.client.deliveryDateTimestamp = moment.unix(item.client.deliveryDateTimestamp.seconds).toDate();
                  carts.push(item);
                }

              for(let y=0; y < carts.length; y++)
              {
                  let item = carts[y];
                  if(moment(item.client.deliveryDateTimestamp).format("DD.MM.YYYY") === currentDay.format("DD.MM.YYYY"))
                  {
                    if(item.status === 2 || item.status === 1)
                    {
                      
                      undone.push(item);

                    }
                    if(item.status === 3)
                    {
                      done.push(item);
                    }
                  }
              }

                this.days.push({
                    fullDate: currentDay.format("YYYY-MM-DD"), 
                    dayOfWeek: currentDay.day(),
                    year: currentDay.year(),
                    month: currentDay.month(),
                    day: currentDay.date(),
                    monthName: currentDay.format('MMMM'),
                    unix: currentDay.unix(),
                    weekNum: currentDay.week(),
                    events: {
                    created: [],
                    undone: undone,
                    done: done,
                    other: []
                    },
                    titleDate: currentDay.format("Do MMMM YYYY")
                })
            }

            if(this.days.length/7 >= 6)
            {
                for(let i=0; i<7;i++)
                {
                    this.days.shift();
                }
            }

            await this.getOtherEvents();

            this.loading = false;
        },
   }
}
</script>

<style>

</style>