<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div :class="['bg-indigo-100']" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                <CubeTransparentIcon :class="['text-indigo-600']" class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full sm:mr-4">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Tworzenie nowego posiłku</DialogTitle>
                <div class="mt-2">
                  <div v-if="!loader" class="border-t border-gray-100">
                    <dl class="divide-y divide-gray-100">
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Nazwa:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="productName" class="sr-only">Nazwa posiłku</label>
                            <input type="text" name="productName" id="productName" v-model="this.mealName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa produktu" />
                          </div>
                          <p v-if="this.errors.mealName" class="text-xs text-red-500 mt-0.5 ml-1">Podaj nazwę posiłku.</p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" :class="['transition ease-in-out duration-200 bg-indigo-600 text-base font-medium hover:bg-indigo-700 focus:ring-indigo-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm' ]" @click="createNewMeal">Dodaj nowy posiłek</button>
              <button type="button" class="transition ease-in-out duration-200 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CubeTransparentIcon } from '@heroicons/vue/outline'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CubeTransparentIcon,
  },
  props: [],
  data() {
    return {
        loader: false,
        mealName: "",
        errors: {
          mealName: false,
        }
    }
  },
  created(){
  },
  methods: {
    createNewMeal(){
      let errors = this.errorCheck()
        if(errors.length === 0){
          this.emitAccept()
        }
    },
    errorCheck(){
      this.errors.mealName = false
      let errors = []
      if(this.mealName.length < 1){
        this.errors.mealName = true
        errors.push('mealName')
      }
      return errors
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept", this.mealName);
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    }
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
