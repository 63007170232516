<template>
    <div v-if="$store.state.cart === null">
        <div class="mt-6 relative block w-full rounded-lg border-2 border-dashed border-gray-400 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <ExclamationIcon class="mx-auto h-12 w-12 text-gray-300"></ExclamationIcon>
          <span class="mt-2 block text-xs font-semibold text-gray-700">Koszyk jest pusty</span>
        </div>
    </div>
    <div v-if="$store.state.cart !== null">
      <div class="bg-white">
        <div class="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8 select-none">
          <div class="flex justify-between items-center">
            <div class="text-3xl font-bold tracking-tight text-gray-900">Koszyk ({{$store.state.cart.type.toUpperCase()}})</div>
            <div class="mb-1 flex items-end justify-end">
              <button type="button" @click="deleteCartAsk = true" class="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Usuń koszyk</button>
              <button type="button" @click="$router.push(`/`)" class="rounded ml-3 bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj inny przedmiot</button>
            </div>
          </div>
          <div class="mt-4">
            <div class="divide-y divide-gray-100">
              <div name="Menu" class="pb-16">
                <div class="text-xl font-medium">Menu</div>
                <div class="mt-2">
                  <p class="mt-2 mb-2 text-sm text-gray-500" id="email-description">Dodaj kolejny posiłek do menu lub kontynuuj przeglądanie.</p>
                  <div class="flex items-center">
                    
                    <div>
                      <input type="text" v-model="newMealName" maxlength="17"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa posiłku" aria-describedby="email-description" />
                    </div>
                    <div class="ml-1">
                      <button type="button" @click.prevent="addNewMeal()" class="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj posiłek</button>
                    </div>
                    
                  </div>
                </div>
                <div v-if="meals.length === 0" class="mt-6 relative block w-full rounded-lg border-2 border-dashed border-red-400 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <ExclamationIcon class="mx-auto h-12 w-12 text-red-500"></ExclamationIcon>
                  <span class="mt-2 block text-xs font-semibold text-gray-700">Brak posiłków</span>
                </div>
                <div ref="scrollToPositions" v-for="(meal, mealIndex) in meals" :key="mealIndex">
                    <div class="mt-4 font-medium text-lg">{{meal.name}} <span class="text-xs">({{meal.positions.length}} poz.)</span> <span class="text-xs text-red-500 cursor-pointer underline hover:text-red-300" @click.prevent="deleteMealModalOn(meal.name)">Usuń posiłek</span></div>
                    <ul v-if="meal.positions.length !== 0" role="list" class="divide-y divide-gray-200 border-b  border-gray-200">
                    <li v-for="(position, positionIdx) in meal.positions" :key="positionIdx" class="flex py-6 sm:py-10">
                    <div class="flex-shrink-0">
                        <img :src="position.product.imageUrl" class="h-24 w-24 rounded-lg object-cover object-center sm:h-32 sm:w-32" />
                    </div>
                    <div class="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                        <div>
                        <div class="flex justify-between sm:grid sm:grid-cols-2">
                            <div class="pr-6">
                            <h3 class="text-sm">
                                <div class="font-medium text-gray-700 hover:text-gray-800">{{ position.product.productName }}</div>
                            </h3>
                            <div v-if="position.product.productType !== undefined && position.product.productType === 'set'">
                              <div :class="['border-transparent text-gray-500 hover:text-gray-700', 'inline-flex items-center border-b-2 text-sm font-medium transition-all duration-300']">
                                <Menu as="div" :class="['relative h-full']">
                                  <div class="h-full">
                                    <MenuButton class="relative max-w-xs h-full text-sm">
                                      <span class="absolute -inset-1.5" />
                                      <span class="sr-only">Open user menu</span>
                                      <p class="text-xs select-none cursor-pointer text-indigo-500 font-medium flex items-center">Produkty wybrane do zestawu <ChevronDownIcon class="h-3 w-3 ml-0.5" aria-hidden="true" /></p>
                                    </MenuButton>
                                  </div>
                                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                    <MenuItems class="absolute left-0 z-50 mt-2 w-80 max-h-48 overflow-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <MenuItem v-for="(slot, indx) in position.product.slots" :key="indx" v-slot="{ active }">
                                        <div :class="[active ? 'bg-gray-100' : '', indx !== 0 ? 'border-t border-gray-200':'', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">
                                          <div class="min-w-0 flex-1 text-sm leading-6">
                                            <img v-if="slot.product.imageUrl !== undefined && slot.product.imageUrl.length > 0" :src="slot.product.imageUrl" class="h-8 w-8 rounded-full inline-block mr-3 object-cover object-center" />
                                            <span class="font-medium text-xxs text-gray-600">{{ slot.product.setProductName }}</span>
                                          </div>
                                        </div>
                                      </MenuItem>
                                    </MenuItems>
                                  </transition>
                                </Menu>
                              </div>
                            </div>
                            <p class="mt-1 text-xs text-gray-500">{{position.product.description}}</p>
                            </div>

                            <div class="text-right text-gray-900 flex-col">
                                <div class="text-xs mb-1 flex justify-end items-center">
                                    <div @click.prevent="deleteProductInCartModalOn(position)" v-tooltip="`Usuń produkt.`" class="flex group items-center cursor-pointer text-red-400 hover:text-red-500 justify-end ml-1 flex-row-reverse">
                                        <TrashIcon class="outline-none h-4 w-4 cursor-pointer text-red-400 group-hover:text-red-500 transition-all duration-300" aria-hidden="true" />
                                        <span class="mr-1">Usuń produkt</span>
                                    </div>
                                </div>
                                <div class="text-xs flex justify-end items-center">
                                    <div>Sztuki: {{position.quantity}}</div>
                                    <div class="flex justify-end ml-1 flex-row-reverse">
                                        <PlusCircleIcon @click.prevent="cartOptions('add', position)" v-tooltip="`Dodaj produkt.`" class="outline-none h-6 w-6 cursor-pointer text-gray-400 hover:text-green-500 transition-all duration-300" aria-hidden="true" />
                                        <MinusCircleIcon @click.prevent="cartOptions('remove', position)" v-tooltip="`Usuń produkt.`" class="outline-none h-6 w-6 cursor-pointer text-gray-400 hover:text-red-500 transition-all duration-300" aria-hidden="true" />
                                    </div>
                                </div>
                                <div class="text-xs">Cena za szt.: {{position.product.unitPrice.toFixed(2)}} PLN</div>
                                <div v-if="position.product.unitDiscount !== undefined && showUnitDiscounts" class="flex items-center justify-end">
                                        <label for="price" class="block text-sm leading-6 text-gray-900 mr-1">Rabat:</label>
                                        <div class="relative w-32 rounded-md shadow-sm">
                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span class="text-gray-500 text-xs">-</span>
                                        </div>
                                        <input @change="checkUnitDiscountField(position.product.unitDiscount, position.product.unitPrice, mealIndex, positionIdx)" type="number" min="0" name="price" id="price" v-model="position.product.unitDiscount" class="block w-full rounded-md border-0 py-0.5 pl-6 pr-9 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:leading-6" placeholder="0.00" />
                                        <div class="absolute inset-y-0 right-0 flex items-center">
                                            <label for="currency" class="sr-only">Currency</label>
                                            <div id="currency" name="currency" class="h-full flex justify-center items-center rounded-md border-0 bg-transparent py-0 pl-2 pr-2 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs">
                                            PLN
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                <div v-if="position.product.unitDiscount !== undefined && position.product.unitDiscount > 0" class="text-xs">Cena za szt. po rabacie: {{(position.product.unitPrice - position.product.unitDiscount).toFixed(2)}} PLN</div>
                                <div v-if="position.product.unitDiscount !== undefined && position.product.unitDiscount > 0" class="font-medium text-sm">Cena całkowita: {{((position.product.unitPrice-position.product.unitDiscount)*position.quantity).toFixed(2)}} PLN</div>
                                <div v-if="position.product.unitDiscount === undefined || position.product.unitDiscount <= 0" class="font-medium text-sm">Cena całkowita: {{((position.product.unitPrice)*position.quantity).toFixed(2)}} PLN</div>
                                <!-- <div v-if="position.product.unitDiscount !== undefined && position.product.unitDiscount === 0" class="text-xs m5-1 flex justify-end items-center">
                                    <div @click.prevent="deleteProductInCartModalOn(position)" v-tooltip="`Usuń produkt.`" class="flex group items-center cursor-pointer text-blue-400 hover:text-blue-500 justify-end ml-1 flex-row-reverse">
                                        <CurrencyDollarIcon class="outline-none h-4 w-4 cursor-pointer text-blue-400 group-hover:text-blue-500 transition-all duration-300" aria-hidden="true" />
                                        <span class="mr-1">Rabatuj pozycję</span>
                                    </div>
                                </div> -->
                            </div>
                            
                        </div>


                        </div>

                        <p class="mt-1 flex space-x-2 text-xs italic">
                        <span class="text-sm text-gray-500">{{position.meal}}</span>
                        </p>
                    </div>
                    </li>
                </ul>
                <div v-if="meal.positions.length === 0" class="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <ExclamationIcon class="mx-auto h-12 w-12 text-gray-300"></ExclamationIcon>
                  <span class="mt-2 block text-xs font-semibold text-gray-700">Posiłek jest pusty</span>
                </div>
                </div>
              </div>


              <!-- <div class="py-16 divide-y divide-gray-100">
                <div></div>
                <div></div>
              </div> -->

              <div name='Usługi, kaucje i produkty dodatkowe' class="py-16">
                <div class="text-xl font-medium">Usługi, kaucje i produkty dodatkowe</div>
                <div class="rounded-md bg-yellow-50 p-4 my-4" v-if="$store.state.cart.type === 'catering'">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-yellow-800">Uwaga!</h3>
                      <div class="mt-2 text-sm text-yellow-700">
                        <p>Wybrałeś(aś) katering. Nie zapomnij doliczyć kaucji za naczynia.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 mt-2 md:grid-cols-3">
                    <div class="bg-white col-span-4">
                        <div class="">
                            <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">

                                <div class="sm:col-start-1">
                                <label class="block text-sm font-medium leading-6 text-gray-900">Nazwa produktu</label>
                                <div class="mt-2">
                                    <input v-if="addonsInputs.type !== 'Kaucja' && addonsInputs.type !== 'Produkt dodatkowy'" type="text" v-model="addonsInputs.name" maxlength="50" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    <select v-if="addonsInputs.type === 'Kaucja' && this.depositProducts.length > 0" id="location" v-model="addonsInputs.name" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <option v-for="product in this.depositProducts" :key="product">{{product}}</option>
                                    </select>
                                    <span class="transition ease-in-out duration-200 text-red-500 hover:text-red-700 cursor-pointer underline text-xs" v-if="addonsInputs.type === 'Produkt dodatkowy' && this.additionalProducts.length === 0" @click.prevent="this.$router.push('/produkty_dodatkowe')">Dodaj najpierw produkty dodatkowe klikając w ten napis.</span>
                                    <select v-if="addonsInputs.type === 'Produkt dodatkowy' && this.additionalProducts.length > 0" id="location" v-model="addonsInputs.name" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <option v-for="product in this.additionalProducts" :key="product">{{product}}</option>
                                    </select>
                                    <span class="transition ease-in-out duration-200 text-red-500 hover:text-red-700 cursor-pointer underline text-xs" v-if="addonsInputs.type === 'Kaucja' && this.depositProducts.length === 0" @click.prevent="this.$router.push('/kaucje')">Dodaj najpierw produkty podlegające kaucji klikając w ten napis.</span>
                                </div>
                                </div>

                                <div>
                                    <div>
                                        <label for="location" class="block text-sm font-medium leading-6 text-gray-900">Typ</label>
                                        <select id="location" v-model="addonsInputs.type" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option>Kaucja</option>
                                            <option>Produkt dodatkowy</option>
                                            <option>Usługa</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label for="price" class="block text-sm font-medium leading-6 text-gray-900">Ilość</label>
                                        <div class="relative mt-2 rounded-md shadow-sm">
                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span class="text-gray-500 sm:text-sm"></span>
                                        </div>
                                        <input type="number" min="1" name="price" id="price" v-model="addonsInputs.quantity" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                                        <div class="absolute inset-y-0 right-0 flex items-center">
                                            <!-- <select id="currency" name="currency" class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                                            <option>PLN</option>
                                            </select> -->
                                            <div class="h-full flex items-center justify-center rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">szt.</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label for="price" class="block text-sm font-medium leading-6 text-gray-900">Cena /szt</label>
                                        <div class="relative mt-2 rounded-md shadow-sm">
                                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span class="text-gray-500 sm:text-sm"></span>
                                        </div>
                                        <input type="number" min="0" name="price" id="price" v-model="addonsInputs.price" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                                        <div class="absolute inset-y-0 right-0 flex items-center">
                                            <label for="currency" class="sr-only">Currency</label>
                                            <div id="currency" name="currency" class="h-full flex justify-center items-center rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                                            PLN
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-col items-center justify-end gap-x-6 py-2">
                            <div class="">
                              <div class="flex items-end justify-end">
                                <button type="button" @click.prevent="addAdditionalProduct()" class="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj produkt</button>
                              </div>
                              <div class="text-xs mt-1 text-red-500 flex items-end justify-end" v-if="errors.addons">Wszystkie pola muszą być wypełnione oraz cena nie może wynosić 0 PLN.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$store.state.additionalProducts.length === 0" class="mt-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <CubeTransparentIcon class="mx-auto h-12 w-12 text-gray-300"></CubeTransparentIcon>
                  <span class="mt-2 block text-xs font-semibold text-gray-700">Brak produktów dodatkowych, usług, kaucji</span>
                </div>
                <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mb-2">
                    <li v-for="(adProduct,adIndx) in $store.state.additionalProducts" :key="adIndx" class="overflow-hidden rounded-xl border border-gray-200">
                    <div :class="['flex items-center gap-x-4 border-b border-gray-200 p-6', adProduct.type === 'Kaucja' ? 'bg-yellow-100' : 'bg-gray-50' ]">
                        <div class="text-sm font-medium leading-6 text-gray-900">{{adProduct.name.substring(0,17)}}<span v-if="adProduct.name.length > 17">...</span></div>
                        <Menu as="div" class="relative ml-auto">
                        <MenuButton class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Open options</span>
                            <DotsHorizontalIcon class="h-5 w-5" aria-hidden="true" />
                        </MenuButton>
                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <MenuItem v-slot="{ active }">
                                <div @click="spliceAddons(adIndx)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']"
                                >Usuń</div>
                            </MenuItem>
                            </MenuItems>
                        </transition>
                        </Menu>
                    </div>
                    <dl :class="['-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6', adProduct.type === 'Kaucja' ? 'bg-yellow-50' : '']">
                        <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Typ</dt>
                        <dd class="flex items-start gap-x-2">
                            <div class="font-medium text-gray-900">{{adProduct.type}}</div>
                            <!-- <div :class="[statuses[client.lastInvoice.status], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset']">{{ client.lastInvoice.status }}</div> -->
                        </dd>
                        </div>
                        <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Cena /szt</dt>
                        <dd class="flex items-start gap-x-2">
                            <div class="font-medium text-gray-900">{{adProduct.price.toFixed(2)}} PLN</div>
                            <!-- <div :class="[statuses[client.lastInvoice.status], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset']">{{ client.lastInvoice.status }}</div> -->
                        </dd>
                        </div>
                        <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Ilość</dt>
                        <dd class="flex items-start gap-x-2">
                            <div class="font-medium text-gray-900">{{adProduct.quantity}}</div>
                            <!-- <div :class="[statuses[client.lastInvoice.status], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset']">{{ client.lastInvoice.status }}</div> -->
                        </dd>
                        </div>
                        <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Cena łączna</dt>
                        <dd class="flex items-start gap-x-2">
                            <div class="font-medium text-gray-900">{{(parseFloat(adProduct.quantity) * parseFloat(adProduct.price)).toFixed(2)}} PLN</div>
                            <!-- <div :class="[statuses[client.lastInvoice.status], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset']">{{ client.lastInvoice.status }}</div> -->
                        </dd>
                        </div>
                    </dl>
                    </li>
                </ul>
              </div>
                
              <div v-if="this.$store.state.cart.type === 'bankiet'" name="Miejsce spotkania" class="py-16">
                <div class="text-xl font-medium">Miejsce spotkania</div>
                <div class="mt-2">
                    <select id="location" v-model="details.partyPlace" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option>Sala</option>
                        <option>Ogród zimowy</option>
                    </select>
                </div>
                <div class="text-xl font-medium mt-4">Ilość gości</div>
                <div>
                      <div class="relative mt-2 rounded-md shadow-sm">
                      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span class="text-gray-500 sm:text-sm"></span>
                      </div>
                      <input @change="checkGuestsAmount()" type="number" min="0" name="price" id="price" v-model="details.guestsAmount" class="block w-full rounded-md border-0 py-1.5 pl-3 pr-9 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                          <!-- <select id="currency" name="currency" class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                          <option>PLN</option>
                          </select> -->
                          <div class="h-full flex items-center justify-center rounded-md border-0 bg-transparent py-0 pl-2 pr-3 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">os.</div>
                      </div>
                      </div>
                  </div>
                </div>

              <div name="Dane klienta" class="py-16">
                <div class="text-xl font-medium">Dane klienta</div>

                <div class="rounded-md bg-red-50 p-4 my-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-red-800">Uwaga!</h3>
                      <div class="mt-1 text-sm text-red-700">
                        <p>Dane klienta oraz rabaty wpisz dopiero przed zapisaniem zamówienia.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                    <label class="block text-sm font-medium leading-6 text-gray-900">Imię i nazwisko*</label>
                    <div class="mt-2">
                    <input type="text" v-model="details.name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. Jan Kowalski" />
                    </div>
                </div>
                <div class="mt-2">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Telefon*</label>
                    <div class="mt-2">
                    <input type="text" v-model="details.phoneNumber" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. +48123456789" />
                    </div>
                </div>
                <div class="mt-2">
                    <label class="block text-sm font-medium leading-6 text-gray-900">E-mail</label>
                    <div class="mt-2">
                    <input type="text" v-model="details.email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. restauracja@wbtank.pl" />
                    </div>
                </div>
                <div class="mt-2">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Adres</label>
                    <div class="mt-2">
                    <input type="text" v-model="details.fullAddress" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. Wrocławska 1, 50-062 Wrocław" />
                    </div>
                </div>
                <div class="mt-2">
                    <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">Adnotacje</label>
                    <div class="mt-2">
                    <textarea rows="2" v-model="details.annotation" name="comment" id="comment" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div class="mt-2">
                    <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">Data realizacji*</label>
                    <div class="mt-2 flex">
                      
                      <input type="datetime-local" :min="this.mindate" v-model="details.deliveryDate" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                       <button type="button" @click.prevent="calendarModalOpened = true" class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ml-2">Kalendarz</button>
                    </div>
                </div>
                <!-- Order summary -->
              <div class="mt-10">
                <div class="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8">
                  <h2 class="sr-only">Order summary</h2>

                  <div class="flow-root">
                    <dl class="-my-4 divide-y divide-gray-200 text-sm">
                      <div class="flex items-center justify-between py-4">
                        <dt class="text-gray-600">Menu</dt>
                        <dd class="font-medium text-gray-900">{{this.menuTotal().toFixed(2)}} PLN</dd>
                      </div>
                      <div class="flex items-center justify-between py-4">
                        <dt class="text-gray-600">Dodatki</dt>
                        <dd class="font-medium text-gray-900">{{this.addonsTotal().toFixed(2)}} PLN</dd>
                      </div>
                      <div class="flex items-center justify-between py-4">
                        <dt class="text-yellow-500">Kaucja</dt>
                        <dd class="font-medium text-yellow-500">{{this.depositTotal().toFixed(2)}} PLN</dd>
                      </div>
                      <div class="flex items-center justify-between py-4">
                        <dt class="text-gray-600">Rabat</dt>
                        <dd class="font-medium text-gray-900">
                          <div>
                            <div class="relative mt-2 rounded-md shadow-sm">
                              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span class="text-gray-500 sm:text-sm"></span>
                              </div>
                              <input type="text" name="price" id="price" v-model="discount" @change="checkDiscountField()" class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0.00" />
                              <div class="absolute inset-y-0 right-0 flex items-center">
                                <div id="currency" name="currency" class="h-full flex justify-center items-center rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                                  PLN
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-xs mt-1 flex justify-end items-center">
                            <div @click.prevent="openUnitDiscounts()" v-tooltip="`Przejdź do rabatowania pozycji`" class="flex group items-center cursor-pointer text-blue-400 hover:text-blue-500 justify-end ml-1 flex-row-reverse">
                                <CurrencyDollarIcon class="outline-none h-4 w-4 cursor-pointer text-blue-400 group-hover:text-blue-500 transition-all duration-300" aria-hidden="true" />
                                <span class="mr-1">Rabatuj konkretne pozycje</span>
                            </div>
                          </div>
                        </dd>
                      </div>
                      <div class="flex items-center justify-between py-4">
                        <dt class="text-base font-medium text-gray-900">Koszt całkowity</dt>
                        <dd class="text-base font-medium text-gray-900">{{this.totalPrice().toFixed(2)}} PLN</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="rounded-md bg-red-50 p-4 mt-2" v-if="errors.fullValidation">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-red-800">Wykryto błąd formularza. Sprawdź następujące rzeczy:</h3>
                      <div class="mt-2 text-sm text-red-700">
                        <ul role="list" class="list-disc space-y-1 pl-5">
                          <li>Sprawdź czy sekcja dane klienta jest wypełniona (Adnotacja  oraz adres są opcjonalne).</li>
                          <li v-if="this.errors.email">Sprawdź adres e-mail. Musi on być zapisany w formacie "x@y.z" lub pozostać pusty.</li>
                          <li>Sprawdź czy każdy posiłek zawiera chociaż jeden produkt.</li>
                          <li v-if="$store.state.cart.type === 'catering'">W przypadku kateringu, sprawdź czy jest doliczona chociaż jedna kaucja (np. za talerze).</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-10">
                <button v-if="meals.length>0" @click.prevent="saveToDb()" class="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Zapisz zamówienie</button>
                <button v-if="meals.length === 0" class="w-full rounded-md border border-transparent opacity-30 bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Zapisz zamówienie</button>
              </div>

              <div class="mt-6 text-center text-sm text-gray-500">
                <div>
                  lub
                  <div @click="$router.push(`/`)" class="font-medium text-indigo-600 hover:text-indigo-500">
                    wybierz kolejne produkty
                    <span aria-hidden="true"> &rarr;</span>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <calendarModal v-if="calendarModalOpened" @closeModal="calendarModalOpened = false"></calendarModal>
    <askForMealDelete v-if="deleteMealAsk" @accept="deleteMeal" :modalData="modalDeleteMeal" @closeModal="deleteMealAsk = false, deleteMealIndx = null" />
    <askForCartDelete v-if="deleteCartAsk" @accept="$store.dispatch(`clearWholeCart`)" :modalData="modalDeleteCart" @closeModal="deleteCartAsk = false" />
    <askForCartDelete v-if="deleteProductInCartAsk" @accept="deleteProductInCart" :modalData="modalDeleteProductInCart" @closeModal="deleteProductInCartAsk = false" />
</template>

<script>
import calendarModal from '@/components/ModalCalendar.vue';
import askForCartDelete from '@/components/AlertModal.vue';
import askForMealDelete from '@/components/AlertModal.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { DotsHorizontalIcon } from '@heroicons/vue/solid';
import moment from 'moment';
import {CubeTransparentIcon, ExclamationIcon, PlusCircleIcon, MinusCircleIcon,TrashIcon,CurrencyDollarIcon,ChevronDownIcon} from '@heroicons/vue/outline';
import Cookies from 'js-cookie';
import {db} from "@/firebase/gfbconf.js";
export default {
   components: {
      calendarModal,ChevronDownIcon,CurrencyDollarIcon,TrashIcon,askForCartDelete, CubeTransparentIcon, askForMealDelete, ExclamationIcon, PlusCircleIcon, MinusCircleIcon, Menu, MenuButton, MenuItem, MenuItems, DotsHorizontalIcon
   },
   data() {
      return {
        calendarModalOpened: false,
        showUnitDiscounts: false,
        additionalProducts: [],
        depositProducts: [],
        minDate: null,
        details: {
          name: "",
          phoneNumber: "",
          fullAddress: "",
          email: "",
          annotation: "",
          deliveryDate: "",
          deliveryDateTimestamp: null,
          partyPlace: 'Sala',
          guestsAmount: 0
        },
        showCartModal: true,
        meals: [],
        errors: {
          addons: false,
          fullValidation: false,
          email: false
        },
        newMealName: "",
        addonsInputs: {
            name: "",
            price: 0,
            quantity: 1,
            type: "Kaucja"
        },
        discount: 0,
        modalDeleteMeal: {
            headerText: "Usuwanie posiłku",
            bodyText: "Czy na pewno chcesz usunąć posiłek? Usunięcie jest nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteMealIndx: null,
        deleteMealAsk: false,
        modalDeleteCart: {
            headerText: "Usuwanie koszyka",
            bodyText: "Czy na pewno chcesz usunąć koszyk? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteCartAsk: false,
        modalDeleteProductInCart: {
            headerText: "Usuwanie produktu z koszyka",
            bodyText: "Czy na pewno chcesz usunąć produkt z koszyka? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteProductInCartAsk: false,
        deleteProductInCartPosition: null,
      }
   },
   watch: {
       [`$store.state.cart`]()
       {
            if(this.$store.state.cart !== null)
            {
                this.packMeals();
            }
       } 
   },
   created() {
      if(this.$store.state.user === null || this.$store.state.user === undefined)
      {
        //wait for user if refresh/from link
        setTimeout(() => {
          if(this.$store.state.user === null || this.$store.state.user === undefined)
          {
            this.$router.push("/");
          }
          else
          {
            if(Cookies.get(`additionalProd-${this.$store.state.user.uid}`) !== undefined)
            {
              this.$store.dispatch("setAdditionalProduct",JSON.parse(Cookies.get(`additionalProd-${this.$store.state.user.uid}`)));
            }
            this.getDepositProducts()
            this.getAdditionalProducts()
          }
        }, 500);
      }else{
        if(Cookies.get(`additionalProd-${this.$store.state.user.uid}`) !== undefined)
        {
          this.$store.dispatch("setAdditionalProduct",JSON.parse(Cookies.get(`additionalProd-${this.$store.state.user.uid}`)));
        }
        this.getDepositProducts()
        this.getAdditionalProducts()
      }

      if(this.$store.state.cart !== null)
      {
          this.packMeals();
      }
      // this.mindate = '2023-09-02'
      this.mindate = moment().format('YYYY-MM-DD')
   },
   computed: {
   },
   methods: {
    log(log){
      console.log(log)
    },
    totalPrice() {
      let total = 0;
      total = total + this.menuTotal() + this.addonsTotal() + this.depositTotal() - this.discount;
      return total;
    },
    addonsTotal() {
      let total = 0;
      for(let i=0;i<this.$store.state.additionalProducts.length;i++)
      {
        if(this.$store.state.additionalProducts[i].type !== "Kaucja"){
        total = total + (this.$store.state.additionalProducts[i].price * this.$store.state.additionalProducts[i].quantity) ;
        }
      }
      return total;
    },
    depositTotal() {
      let total = 0;
      for(let i=0;i<this.$store.state.additionalProducts.length;i++)
      {
        if(this.$store.state.additionalProducts[i].type === "Kaucja"){
        total = total + (this.$store.state.additionalProducts[i].price * this.$store.state.additionalProducts[i].quantity) ;
        }
      }
      return total;
    },
    menuTotal() {
      let total = 0;
      for(let i=0; i<this.meals.length; i++)
      {
        let amountToAdd = 0;
        for(let y=0; y<this.meals[i].positions.length; y++)
        {
          // amountToAdd = amountToAdd + (this.meals[i].positions[y].price);
          amountToAdd = amountToAdd + parseFloat(((this.meals[i].positions[y].product.unitPrice - this.meals[i].positions[y].product.unitDiscount) * this.meals[i].positions[y].quantity).toFixed(2));
        }
        total = total + amountToAdd;
      }
      return total;
    },
        openUnitDiscounts(){
          this.showUnitDiscounts = true
          // scrollToPositions
          let el = this.$refs.scrollToPositions[0]
          el.scrollIntoView({ behavior: 'smooth' })
          // this.$refs.scrollToPositions.scrollIntoView({ behavior: 'smooth' });
        },
        async getAdditionalProducts(){
          let data = await db.collection("additionalProducts").doc('additionalProducts').get()
          let additionalProducts = data.data().additionalProducts
          this.additionalProducts = additionalProducts
        },
        async getDepositProducts(){
          let data = await db.collection("depositProducts").doc('depositProducts').get()
          let depositProducts = data.data().depositProducts
          this.depositProducts = depositProducts
        },
        deleteProductInCartModalOn(position){
          this.deleteProductInCartPosition = position
          this.deleteProductInCartAsk = true
        },
        deleteProductInCart(){
          this.deleteProductInCartAsk = false
          this.cartOptions('delete', this.deleteProductInCartPosition)
          this.deleteProductInCartPosition = null
        },
        deleteMealModalOn(index){
          this.deleteMealIndx = index
          this.deleteMealAsk = true
        },
        validation()
        {
          let errors = 0;
          if(this.details.name.length === 0) errors++;
          if(this.details.phoneNumber.length === 0) errors++;
          if(this.details.email.length > 0){
            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.details.email.toLowerCase()) === false){
              errors++
              this.errors.email = true;
            }
          }
          // if(this.details.fullAddress.length === 0) errors++;
          if(this.details.deliveryDate.length === 0) errors++;
          if(this.meals.length === 0) errors++;

          for(let i=0; i<this.meals.length;i++)
          {
            if(this.meals[i].positions.length === 0) errors++;
          }

          if(this.$store.state.cart.type === 'catering')
          {
            let caution = 0;
            for(let i=0; i<this.$store.state.additionalProducts.length; i++)
            {
              if(this.$store.state.additionalProducts[i].type === "Kaucja")
              {
                caution = caution + 1;
              }
            }
            if(caution === 0)
            {
              errors++;
            }
          }


          return errors;
        },
        async saveToDb()
        {
          this.errors.fullValidation = false;
          this.errors.email = false;
          let amountOfErrors = this.validation();
          if(amountOfErrors > 0)
          {
            this.errors.fullValidation = true;
            return;
          }
          this.details.deliveryDateTimestamp = moment(this.details.deliveryDate, "YYYY-MM-DDTHH:mm").toDate()
          if(this.$store.state.cart.type !== 'bankiet'){
            this.details.partyPlace = null
            this.details.guestsAmount = 0
          }
          let data = {
            type: this.$store.state.cart.type,
            client: this.details,
            created: {
              unix: moment().unix(),
              uid: this.$store.state.user.uid,
              user: this.$store.state.user.displayName,
              userMail: this.$store.state.user.email,
              phone: this.$store.state.userData.contact.phone
            },
            menu: this.meals,
            additionals: this.$store.state.additionalProducts,
            status: 1,
            discount: parseFloat(this.discount),
            clientNotification: null
          }

          try{
            let id = db.collection("Carts").doc().id
            data.id = id
            await db.collection("Carts").doc(id).set(data);
            this.$store.dispatch(`clearWholeCart`);
            this.$store.dispatch('clearAdditionalProduct');
            this.$router.push(`/zamowienie?id=${id}`)
          }
          catch(error)
          {
            console.log(error);
          }
        },
        deleteMeal()
        {
          this.$store.dispatch('deleteMeal', this.deleteMealIndx);
          this.deleteMealAsk = false
          this.deleteMealIndx = null
          this.packMeals();
        },
        addNewMeal()
        {
          if(this.newMealName.length === 0) return;
          if(this.$store.state.cart.meals.includes(this.newMealName)) return;
          this.$store.dispatch("addNewMeal", this.newMealName);
          this.newMealName = "";
          this.packMeals();
        },
        checkUnitDiscountField(positionUnitDiscount, positionUnitPrice, mealIndex, positionIndex){
            if(positionUnitDiscount.length === 0)
            {
                positionUnitDiscount = 0;
            }
            if(positionUnitDiscount < 0)
            {
                positionUnitDiscount = 0;
            }
            else
            {
                positionUnitDiscount = parseFloat(positionUnitDiscount);
            }
            if(positionUnitDiscount > positionUnitPrice)
            {
                positionUnitDiscount = 0;
                this.$store.commit('setNotification',{
                      show: true,
                      head: "Nie udało się uzupełnić rabatu!",
                      subheader: "Rabat jest wyższy niż wartość pozycji.",
                      success: false
                    });
            }
            positionUnitDiscount = parseFloat(positionUnitDiscount)
            positionUnitDiscount = parseFloat((positionUnitDiscount).toFixed(2))
            this.meals[mealIndex].positions[positionIndex].product.unitDiscount = positionUnitDiscount
        },
        checkGuestsAmount(){
          if(this.details.guestsAmount.length === 0)
          {
              this.details.guestsAmount = 0;
          }
          if(this.details.guestsAmount < 0)
          {
              this.details.guestsAmount = 0;
          }
          else
          {
              this.details.guestsAmount = parseFloat(this.details.guestsAmount);
          }

          this.details.guestsAmount = Math.round(this.details.guestsAmount)
          this.details.guestsAmount = parseFloat(this.details.guestsAmount)
        },
        checkDiscountField()
        {
          if(this.discount.length === 0)
          {
              this.discount = 0;
          }
          if(this.discount < 0)
          {
              this.discount = 0;
          }
          else
          {
              this.discount = parseFloat(this.discount);
          }
          if(this.discount > this.menuTotal() + this.addonsTotal())
          {
              this.discount = 0;
              this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się uzupełnić rabatu!",
                    subheader: "Rabat jest wyższy niż wartość produktów oraz usług.",
                    success: false
                  });
          }

          this.discount = parseFloat(this.discount)
        },
        spliceAddons(indx)
        {
          this.$store.dispatch("spliceAdditionalProduct", indx);
        },
        addAdditionalProduct()
        {
          if(this.addonsInputs.name.length === 0){
            this.errors.addons = true;
            return;
          };
          if(this.addonsInputs.price === 0){
            this.errors.addons = true;
            return;
          }
          if(this.addonsInputs.quantity < 1){
            this.errors.addons = true;
            return;
          }
          this.$store.dispatch("addAdditionalProduct", {
            name: this.addonsInputs.name,
            price: this.addonsInputs.price,
            type: this.addonsInputs.type,
            quantity: this.addonsInputs.quantity
          });
          this.addonsInputs.name = "";
          this.addonsInputs.price = 0;
          this.addonsInputs.quantity = 1;
          this.addonsInputs.type = "Kaucja";

          this.errors.addons = false;
        },
        cartOptions(value, current)
        {
        if(value === 'delete')
        {
            this.$store.dispatch('deleteFromCart', current);
            this.packMeals();
        }
        if(value === 'remove')
        {
            this.$store.dispatch('removeFromCart', current);
            this.packMeals();
        }
        else if(value === 'add')
        {
            
            this.$store.dispatch('addSingleToCart', current);
            this.packMeals();
        }
        },
       packMeals()
       {
           this.meals = [];
           for(let meal in this.$store.state.cart.meals)
           {
               let groupedMeal = {
                   name: this.$store.state.cart.meals[meal],
                   positions: []
               }
               this.meals.push(groupedMeal);
               for(let i=0; i<this.$store.state.cart.positions.length;i++)
               {
                   if(this.$store.state.cart.positions[i].meal === groupedMeal.name)
                   {
                       groupedMeal.positions.push(this.$store.state.cart.positions[i]);
                   }
               }
           }
       }
   }
}
</script>

<style>

</style>