<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div :class="['bg-indigo-100']" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                <CubeTransparentIcon :class="['text-indigo-600']" class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full sm:mr-4">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Duplikuj zamówienie</DialogTitle>
                <div class="rounded-md bg-red-50 p-4 mt-2" v-if="showError">
                    <div class="flex">
                    <div class="flex-shrink-0">
                        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Wystąpił błąd! </h3>
                        <div class="mt-2 text-sm text-red-700">
                        <ul role="list" class="list-disc space-y-1 pl-5">
                            <li>Sprawdź czy wszystkie wymagane pola są wypełnione.</li>
                            <li v-if="emailError">Sprawdź adres e-mail. Musi on być zapisany w formacie "x@y.z" lub pozostać pusty.</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="mt-2">
                    <div>
                        <label class="block text-sm font-medium leading-6 text-gray-900">Imię i nazwisko*</label>
                        <div class="mt-2">
                        <input type="text" v-model="newClient.name"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. Jan Kowalski" />
                    </div>
                <div class="mt-2">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Telefon*</label>
                    <div class="mt-2">
                    <input type="text" v-model="newClient.phoneNumber" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. +48123456789" />
                    </div>
                </div>
                <div class="mt-2">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                    <div class="mt-2">
                    <input type="text" v-model="newClient.email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. +48123456789" />
                    </div>
                </div>
                <div class="mt-2">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Adres</label>
                    <div class="mt-2">
                    <input type="text" v-model="newClient.address"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np. Wrocławska 1, 50-062 Wrocław" />
                    </div>
                </div>
                <div class="mt-2">
                    <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">Adnotacje</label>
                    <div class="mt-2">
                    <textarea rows="2" name="comment" v-model="newClient.annotation" id="comment" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div class="mt-2">
                    <label for="comment" class="block text-sm font-medium leading-6 text-gray-900">Data realizacji*</label>
                    <div class="mt-2">
                    <input type="datetime-local" :min="minDate" v-model="newClient.date"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" @click.prevent="createNewOrder" :class="['bg-indigo-600 text-base font-medium hover:bg-indigo-700 focus:ring-indigo-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm' ]">Duplikuj zamówienie</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {db, storage} from "@/firebase/gfbconf.js";
import moment from 'moment';
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CubeTransparentIcon } from '@heroicons/vue/outline'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
export default {
  components: {
    LoadingSpinnerHub,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CubeTransparentIcon,
  },
  props: ['order'],
  data() {
    return {
        minDate: null,
        showError: false,
        emailError: false,
        newClient: {
            name: "",
            phoneNumber: "",
            email: "",
            address: "",
            annotation: "",
            date: ""
        }
    }
  },
  created(){
      this.minDate = moment().format('YYYY-MM-DDTHH:mm')
  },
  methods: {
    validation(order)
    {
          let errors = 0;
          if(order.name.length === 0) errors++;
          if(order.phoneNumber.length === 0) errors++;
          // if(order.fullAddress.length === 0) errors++;
          if(order.email.length > 0){
            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(order.email.toLowerCase()) === false){
              errors++
              this.emailError = true;
            }
          }
          if(order.deliveryDate.length === 0) errors++;
          if(order.length === 0) errors++;

          return errors;
    },
    async createNewOrder()
    {
        this.showError = false;
        this.emailError = false
        let newOrder = Object.assign({}, JSON.parse(JSON.stringify(this.order)));
        // Reset status
        newOrder.status = 1;
        // set new client credentials
        newOrder.client.annotation = this.newClient.annotation;
        newOrder.client.fullAddress = this.newClient.address;
        newOrder.client.name = this.newClient.name;
        newOrder.client.phoneNumber = this.newClient.phoneNumber;
        newOrder.client.email = this.newClient.email;
        newOrder.client.deliveryDate = this.newClient.date;
        newOrder.clientNotification = null;
        newOrder.created = {
              unix: moment().unix(),
              uid: this.$store.state.user.uid,
              user: this.$store.state.user.displayName,
              userMail: this.$store.state.user.email,
              phone: this.$store.state.userData.contact.phone
        }


        newOrder.client.deliveryDateTimestamp = moment(this.newClient.date, "YYYY-MM-DDTHH:mm").toDate()

        let errors = 0;
        errors = this.validation(newOrder.client);
        if(errors > 0) {
            this.showError = true;
            return;
        }
        else{
          try{
            let id = db.collection("Carts").doc().id
            await db.collection("Carts").doc(id).set(newOrder);
            this.$store.dispatch(`clearWholeCart`);
            this.$store.dispatch('clearAdditionalProduct');
            window.location.replace(`/zamowienie?id=${id}`);
          }
          catch(error)
          {
            console.log(error);
          }            
        }

    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    }
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
