<template>
    <div class="flex justify-between items-center mb-4">
        <span class="text-xl font-semibold text-gray-800">Galeria zdjęć</span>
        <!-- <div @click.prevent="newImageInGalery = true" class="cursor-pointer transition ease-in-out duration-200 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj nowe zdjęcie</div> -->
    </div>
    <div class="relative">
    <Transition name="fade">
      <div class="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="flex flex-col overflow-hidden rounded-lg mb-4 shadow-lg" v-for="(image, index) in images" :key="index">
          <div @click.prevent="showFullScreenImg = true, fullScreenImg = image.imageUrl, fullScreenText = image.imageTitle" class="hover:opacity-60 cursor-pointer transition ease-in-out duration-200">
          <div class="flex-shrink-1 relative">
            <img class="h-30rem w-full object-cover" :src="image.imageUrl"/>
            <div class="absolute bottom-0 left-0 right-0 px-4 py-2 bg-black opacity-60 shadow-2xl select-none">
                <span class="text-white float-right">{{ image.imageTitle }} </span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <EmptyState class="mt-4" v-if="images.length === 0" />
  <TransitionRoot as="template" :show="showFullScreenImg">
    <Dialog as="div" class="relative z-10" @close="closeFullScreenImg()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl  sm:p-6">
              <div class="sm:flex sm:items-start">
                  <img class="w-full" :src="fullScreenImg" alt="" />
              </div>
              <div v-if="fullScreenText.length > 0" class="flex w-full mt-2 text-lg justify-end">
                {{this.fullScreenText}}
              </div>
              <div class=" sm:mt-2 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="closeFullScreenImg()" ref="cancelButtonRef">Zamknij</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {db} from "@/firebase/gfbconf.js"
import EmptyState from '@/components/EmptyState.vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
export default {
   components: {
      EmptyState,
      Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
  },
   data() {
      return {
        queryLoading: false,
        dbListener: null,
        images: [],
        showFullScreenImg: false,
        fullScreenImg: "",
        fullScreenText: "",
      }
   },
   created() {
        this.getAllImages()
   },
   beforeUnmount() {
      if(this.dbListener !== null){
         this.dbListener();
      }
   },
   methods: {
        closeFullScreenImg(){
            this.showFullScreenImg = false
            setTimeout(() => {
              this.fullScreenImg = ''
              this.fullScreenText = ''
            }, 300);
          },
      getAllImages()
      {
         this.dbListener = db.collection("imageGallery").doc('imageGallery')
         .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            this.images = []
            let images = querySnapshot.data().imageGallery
            this.images = images
            this.queryLoading = false;
         });
      },
   }
}
</script>

<style>

</style>