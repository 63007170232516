<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-7xl sm:w-full">
            <div class="sm:flex sm:items-start">
              <div v-if="loadingCategories" class="w-full mt-8">
                <LoadingSpinnerHub/>
              </div>
              <div class="w-full" v-if="!loadingCategories">
                <header class="bg-white w-full shadow">
                <div class="mx-auto max-w-full px-4 py-6 sm:px-6 lg:px-8">
                    <div class="text-3xl font-bold flex flex-wrap gap-2 tracking-tight text-gray-900">
                    <button @click="changeCategory(category)" v-for="(category, index) in categories" :key="index" type="button" :class="{'transition ease-in-out duration-200 rounded-full px-4 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset': true, 'bg-indigo-500 ring-indigo-600 text-white cursor-default pointer-events-none': category.categoryName === current.categoryName, 'bg-white ring-indigo-300 text-gray-900 hover:bg-indigo-400 hover:text-white': category.categoryName !== current.categoryName}">{{category.categoryName}}</button>
                    </div>
                </div>
                </header>
                <main class="max-h-screen2/3 overflow-y-auto">
                  <div class="mx-auto max-w-7xl">
                    <!-- Your content -->

                    <div class="">
                      <div class="mx-auto max-w-full px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                        <h2 class="sr-only">Products</h2>

                        <div v-if="!loadingCategories" class="grid grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-x-6 lg:gap-y-10 xl:grid-cols-3 xl:gap-x-8">
                          <div v-for="product in Object.keys(current.categoryProducts)" :key="product.id" class="relative flex flex-col rounded-lg border border-gray-200 bg-white">
                            <div class="relative cursor-pointer group aspect-h-4 aspect-w-3 bg-gray-200 aspect-none h-96">
                                <img v-show="current.categoryProducts[product].imageLoaded" @load="current.categoryProducts[product].imageLoaded = true" :src="current.categoryProducts[product].imageUrl" class="h-full rounded-t-md w-full object-cover object-center" />
                                <div v-if="!current.categoryProducts[product].imageLoaded" class="relative flex justify-center items-center w-full h-full">
                                <LoaderData />
                                </div>
                                <div @click.prevent="showFullScreenImg = true, fullScreenImg = current.categoryProducts[product].imageUrl" :class="['transition ease-in-out duration-300 absolute left-0 top-0 h-full w-full opacity-0 group-hover:opacity-100']">
                                  <div class="relative flex justify-end items-end w-full h-full">
                                    <div class="absolute left-0 top-0 w-full h-full bg-white opacity-40"></div>
                                    <ArrowsExpandIcon class="h-8 w-8 ml-1.5 text-gray-800 opacity-80  pr-2 pb-1" aria-hidden="true" />
                                  </div>
                                </div>
                            </div>
                            <div class="flex flex-1 flex-col space-y-2 p-4">
                              <h3 class="text-sm font-medium text-gray-900">
                                  <span aria-hidden="true" />
                                  {{ current.categoryProducts[product].productName }}
                              </h3>
                              <p class="text-sm text-gray-500">{{ current.categoryProducts[product].description }}</p>
                              <div class="flex flex-1 flex-col justify-end">
                                <p class="text-sm italic text-gray-500">Cena:</p>
                                <p class="text-base font-medium text-gray-900">{{ current.categoryProducts[product].unitPrice.toFixed(2) }} PLN /szt</p>
                              </div>
                            </div>
                            <Transition name="fade">
                            <div v-if="$store.state.authIsReady" class="relative w-full border-t border-gray-200 bg-gray-50">
                                  <div class="px-4 flex justify-center">
                                    <div class="pt-3 pb-1 flex justify-end">
                                      <div>
                                        <label class="block text-xs font-medium text-gray-900">Ilość</label>
                                        <div class=" sm:mt-0 sm:col-span-2">
                                            <div class="flex max-w-lg">
                                                <div class="mt-0.5 relative rounded-md shadow-sm">
                                                <input type="number" @change="checkProductionCost(current,product)" v-model="current.categoryProducts[product].pickedAmount" name="price" id="price" class="flex-1 block w-full py-1.5 pl-3 pr-0 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Production Cost" aria-describedby="price-currency" />
                                                </div>
                                                <div class="border border-l-0 block mt-0.5 pl-2 pr-3 py-1.5 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                  szt.
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="text-xxs flex justify-center mb-1 text-gray-700">
                                  Edycja posiłku: {{this.currentEditMeal}}
                                </div>
                                <div  v-if="current.categoryProducts[product].productType === undefined || current.categoryProducts[product].productType !== 'set'" @click.prevent="addProductToMeal(current.categoryProducts[product])" class="transition ease-in-out duration-200 flex cursor-pointer text-white justify-center items-center py-3 bg-indigo-500 rounded-b-md hover:opacity-75">
                                  <ShoppingCartIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                                  Dodaj do posiłku
                                  <span class="text-xs ml-1.5 mt-0.5">({{(current.categoryProducts[product].unitPrice * current.categoryProducts[product].pickedAmount).toFixed(2)}} PLN)</span>
                                </div>
                                <div @click.prevent="configureSet(current, product)" v-if="current.categoryProducts[product].productType !== undefined && current.categoryProducts[product].productType === 'set'" :class="['transition ease-in-out duration-200 flex text-white justify-center items-center py-3 bg-green-600 rounded-b-md', current.categoryProducts[product].pickedMeal !== 'Brak' ? 'cursor-pointer hover:opacity-75' : 'pointer-events-none opacity-60']">
                                  <ShoppingBagIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                                  Konfiguruj zestaw
                                  <span class="text-xs ml-1.5 mt-0.5">({{(current.categoryProducts[product].unitPrice * current.categoryProducts[product].pickedAmount).toFixed(2)}} PLN)</span>
                                </div>
                            </div>
                            </Transition>
                          </div>
                        </div>
                        <div v-if="!loadingCategories && Object.keys(current.categoryProducts).length === 0">
                            <EmptyState />
                          </div>
                        <div v-if="loadingCategories">
                          <LoadingSpinnerHub/>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
            <div class="mt-5 p-4 sm:mt-4 sm:p-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="transition ease-in-out duration-200 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="showFullScreenImg">
    <Dialog as="div" class="relative z-10" @close="closeFullScreenImg()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl  sm:p-6">
              <div class="sm:flex sm:items-start">
                  <img class="w-full" :src="fullScreenImg" alt="" />
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="closeFullScreenImg()" ref="cancelButtonRef">Zamknij</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="showConfigureSet">
    <Dialog as="div" class="relative z-10" @close="closeFullScreenImg()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl  sm:p-6">
              <div v-if="configureSetItem !== null">
                <!-- {{configureSetItem}} -->
                <!-- <div @click="log(configureSetItem.slots)">
                  Test
                </div> -->
                      <table class="w-full whitespace-nowrap text-left text-sm leading-6">
                          <colgroup>
                          <col class="w-full" />
                          <col />
                          <col />
                          </colgroup>
                          <tbody>
                          <tr class="border-b border-gray-100">
                              <td class="max-w-0 px-0 py-5 align-top">
                              <div class="flex items-center">
                                  <img v-if="configureSetItem.imageUrl !== undefined && configureSetItem.imageUrl.length > 0" :src="configureSetItem.imageUrl" class="h-10 w-10 rounded-full inline-block mr-3 object-cover object-center" />
                                  <div class="w-full pr-8">
                                      <div class="truncate font-medium text-gray-900" v-tooltip="`${configureSetItem.productName !==undefined && configureSetItem.productName.length > 0 ? configureSetItem.productName : '-'}`">{{ configureSetItem.productName !==undefined && configureSetItem.productName.length > 0 ? configureSetItem.productName : '-' }}</div>
                                      <div class="truncate text-gray-500" v-tooltip="`${configureSetItem.description !== undefined && configureSetItem.description.length > 0 ? configureSetItem.description : '-'}`">{{ configureSetItem.description !== undefined && configureSetItem.description.length > 0 ? configureSetItem.description : '-' }}</div>
                                  </div>
                              </div>
                              </td>
                              <td class="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                <div class="w-28 flex justify-end">
                                  <div class="w-full">
                                    <div>
                                        <div class="flex max-w-lg">
                                            <div class="mt-0.5 relative rounded-md shadow-sm">
                                            <input type="number" @change="checkConfigureSetPickedAmount()" v-model="configureSetItem.pickedAmount" name="price" id="price" class="flex-1 block w-full py-1.5 pl-3 pr-0 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Production Cost" aria-describedby="price-currency" />
                                            </div>
                                            <div class="border border-l-0 block mt-0.5 pl-2 pr-3 py-1.5 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                              szt.
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                  <!-- {{ configureSetItem.pickedAmount !==undefined && configureSetItem.pickedAmount > 0 ? configureSetItem.pickedAmount : '-'}} -->
                              </td>
                              <td class="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                  <span>{{ (configureSetItem.unitPrice * configureSetItem.pickedAmount).toFixed(2) }}</span> PLN
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <div v-if="configureSetItem !== undefined && configureSetItem.productType === 'set' && configureSetItem.slots !== undefined" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                          <div>
                            <label for="productType" class="sr-only">Liczba produktów wybieranych przez klienta</label>
                            <div>
                              <ul role="list" class="grid gap-5 grid-cols-2 sm:grid-cols-3">
                                <!-- <li v-for="(slot, index) in configureSetItem.slots" :key="index" class="transition ease-in-out duration-200 cursor-pointer opacity-100 hover:opacity-60 col-span-1 flex rounded-md shadow-sm">
                                  <div class="p-4 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                      <div class="w-16 h-16 rounded-full bg-gray-50 flex items-center justify-center text-xxs text-center">
                                        Wybór<br />klienta<br />nr {{index+1}}
                                      </div>
                                  </div>
                                </li> -->
                                <li v-for="(slot, index) in configureSetItem.slots" :key="index" class="w-full">
                                    
                                    <Menu as="div" :class="['relative h-full w-full']">
                                        <MenuButton class="relative w-full">
                                          <div class="transition ease-in-out duration-200 cursor-pointer opacity-100 hover:opacity-60 col-span-1 flex rounded-md shadow-sm">
                                            <div v-if="slot.picked === false" class="p-4 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                                <div class="w-16 h-16 rounded-full bg-gray-50 flex items-center justify-center text-xxs text-center">
                                                  Wybór<br />klienta<br />nr {{index+1}}
                                                </div>
                                                <span class="text-xxs mt-2 text-blue-500">Wybierz</span>
                                            </div>
                                            <div v-if="slot.picked === true" class="p-4 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                                <img :src="slot.product.imageUrl" class="h-16 w-16 rounded-full inline-block object-cover object-center" />
                                                <span class="text-xxs mt-2">{{slot.product.setProductName}}</span>
                                            </div>
                                          </div>
                                        </MenuButton>
                                      <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                        <MenuItems class="absolute left-0 z-50 mt-2 w-80 max-h-48 overflow-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <MenuItem v-for="(product, indx) in configureSetItem.availableSetProducts" :key="indx" v-slot="{ active }">
                                            <div @click.stop="configureSetItem.slots[index].picked = true, configureSetItem.slots[index].product = product" :class="[active ? 'bg-gray-100' : '', indx !== 0 ? 'border-t border-gray-200':'', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">
                                              <div class="min-w-0 flex-1 text-sm leading-6">
                                                <img v-if="product.imageUrl !== undefined && product.imageUrl.length > 0" :src="product.imageUrl" class="h-8 w-8 rounded-full inline-block mr-3 object-cover object-center" />
                                                <span class="font-medium text-xxs text-gray-600">{{ product.setProductName }}</span>
                                              </div>
                                            </div>
                                          </MenuItem>
                                        </MenuItems>
                                      </transition>
                                    </Menu>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <!-- <p v-if="this.errors.slots" class="text-xs text-red-500 mt-0.5 ml-1">Dodaj przynajmniej jeden slot.</p> -->
                        </dd>
                      </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button @click.prevent="addProductToMeal(this.configureSetItem)" :disabled="checkAllPicked()" type="button" :class="['transition ease-in-out duration-300 inline-flex w-full justify-center text-white rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto', checkAllPicked() ? 'opacity-50 pointer-events-none' : 'hover:opacity-75']" ref="acceptButtonRef">
                  <ShoppingCartIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                  Dodaj do koszyka
                </button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="closeConfigureSet()" ref="cancelButtonRef">Zamknij</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { CubeTransparentIcon, ShoppingCartIcon } from '@heroicons/vue/outline'
import EmptyState from './EmptyState.vue';
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import {db} from "@/firebase/gfbconf.js";
export default {
  components: {
    EmptyState,
    LoadingSpinnerHub,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CubeTransparentIcon,
    ShoppingCartIcon,
    Menu, MenuButton, MenuItem, MenuItems
  },
  props: ['mealName'],
  data() {
    return {
        showFullScreenImg: false,
        showConfigureSet: false,
        configureSetItem: null,
        showFullScreenImg: false,
        fullScreenImg: "",
        current: {
            categoryName: "",
            categoryProducts: null
        },
        categories: [],
        loadingCategories: true,
        currentEditMeal: 'x'
    }
  },
  created(){
    this.downloadCategories()
    this.currentEditMeal = this.mealName
  },
  methods: {
    checkConfigureSetPickedAmount()
    {
        this.configureSetItem.pickedAmount = Math.round(this.configureSetItem.pickedAmount)
        if(this.configureSetItem.pickedAmount.length === 0)
        {
            this.configureSetItem.pickedAmount = 1;
        }
        if(this.configureSetItem.pickedAmount < 1)
        {
            this.configureSetItem.pickedAmount = 1;
        }
        else
        {
            this.configureSetItem.pickedAmount = parseFloat(this.configureSetItem.pickedAmount);
        }
    },
    checkAllPicked(){
      if(this.configureSetItem !== null){
      const toPickAmount = this.configureSetItem.slots.length
      let pickedAmount = 0
      for (let i = 0; i < this.configureSetItem.slots.length; i++) {
        const element = this.configureSetItem.slots[i];
        if(element.picked){
          pickedAmount++
        }
      }
      let toReturn = true
      if(toPickAmount === pickedAmount){
        toReturn = false
      }else{
        toReturn = true
      }
      return toReturn
      }
    },
    closeConfigureSet(){
      this.showConfigureSet = false
      setTimeout(() => {
        this.configureSetItem = null
      }, 200);
    },
    configureSet(current, product){
      this.showConfigureSet = true
      // z zapisywaniem
      // this.configureSetItem = current.categoryProducts[product]

      // bez zapisywania
      this.configureSetItem = JSON.parse(JSON.stringify(current.categoryProducts[product]))
    },
    addProductToMeal(product){
      if(product.productType === undefined || product.productType !== 'set'){
        this.$emit("addProduct", product);
      }else{
        this.closeConfigureSet()
        setTimeout(() => {
          this.$emit("addProduct", product);
        }, 300);
      }
    },
    closeFullScreenImg(){
      this.showFullScreenImg = false
      setTimeout(() => {
        this.fullScreenImg = '' 
      }, 300);
    },
    checkProductionCost(current,product){
        current.categoryProducts[product].pickedAmount = Math.round(current.categoryProducts[product].pickedAmount)
        if(current.categoryProducts[product].pickedAmount.length === 0)
        {
            current.categoryProducts[product].pickedAmount = 1;
        }
        if(current.categoryProducts[product].pickedAmount < 1)
        {
            current.categoryProducts[product].pickedAmount = 1;
        }
        else
        {
            current.categoryProducts[product].pickedAmount = parseFloat(current.categoryProducts[product].pickedAmount);
        }
    },
    changeCategory(category){
        this.current.categoryName = category.categoryName
        this.current.categoryProducts = category.products
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    async downloadCategories(){
        this.loadingCategories = true
        this.categories = []
        this.current.categoryName = ""
        this.current.categoryProducts = null
        let categoriesDocs = await db.collection("ProductCategories").get()
        for (let i = 0; i < categoriesDocs.docs.length; i++) {
            const element = categoriesDocs.docs[i].data();
            let productsToSort = []
            for (let x = 0; x < Object.keys(element.products).length; x++) {
            const productId = Object.keys(element.products)[x]
            const product = element.products[productId];
            product.pickedAmount = 1
            product.imageLoaded = false
            product.id = productId
            productsToSort.push(product)
            }
            productsToSort.sort(function(a,b) {
                return a.soldAmount - b.soldAmount;
            });
            productsToSort.reverse()
            element.products = productsToSort
            this.categories.push(element)
        }
        this.categories.sort((a,b) => a.sortIndex - b.sortIndex)
        if(this.categories[0] !== undefined){
            this.current.categoryName = this.categories[0].categoryName
            this.current.categoryProducts = this.categories[0].products
        }else{
            this.current.categoryName = null
            this.current.categoryProducts = []
        }
        this.loadingCategories = false
    }
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
