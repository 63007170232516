<template>
    <div class="text-xl font-semibold text-gray-800 mb-2">
        Zarządzaj produktami podlegającym kaucji
    </div>
  <ul role="list" class="grid grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-3 xl:gap-x-4">
    <li @click.prevent="newDepositProduct = true" v-tooltip="`Dodaj nowy produkt`" class="flex items-center justify-between cursor-pointer transition ease-in-out duration-100 hover:opacity-60 overflow-hidden rounded-xl border border-gray-200">
        <div class="flex w-full bg-white items-center py-6 px-4 justify-between">
            <div class="w-10/12">
                <div class="truncate text-sm font-medium text-green-500">Dodaj nowy produkt</div>
            </div>
            <div>
                <PlusIcon class="h-5 w-5 text-green-600 " />
            </div>
        </div>
    </li>
    <li v-for="(product,ind) in depositProducts" :key="ind" class="transition ease-in-out duration-100 overflow-hidden rounded-xl border border-gray-200">
      <div class="flex items-center gap-x-4 bg-gray-100 py-6 px-4">
        <div class="flex w-full items-center justify-between">
            <div class="w-10/12">
                <div class="truncate text-sm font-medium text-gray-700" >{{product}}</div>
            </div>
            <div>
                <TrashIcon @click.prevent="deleteDepositProduct = true ,deleteDepositProductId = product" class="transition ease-in-out duration-200 h-5 w-5 text-red-500 hover:text-red-700 cursor-pointer" />
            </div>
        </div>
      </div>
    </li>
  </ul>
  <EmptyState class="mt-4" v-if="depositProducts.length === 0" />
  <NewDepositProductModal v-if="newDepositProduct" @closeModal="newDepositProduct = false"></NewDepositProductModal>
  <askForDepositProductDelete v-if="deleteDepositProduct" @accept="deleteDepositProductFromDb" :modalData="modalDataDepositProduct" @closeModal="deleteDepositProduct = false, deleteDepositProductId = null"></askForDepositProductDelete>
</template>

<script>
import {db, firebase} from "@/firebase/gfbconf.js"
import { Menu, MenuButton, MenuItem, MenuItems, Listbox,ListboxButton,ListboxLabel,ListboxOption,ListboxOptions, } from '@headlessui/vue'
import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { ChevronDownIcon, PlusIcon, TrashIcon } from '@heroicons/vue/outline'
import EmptyState from '@/components/EmptyState.vue';
import NewDepositProductModal from '@/components/NewDepositProductModal.vue';
import askForDepositProductDelete from '@/components/AlertModal.vue';

export default {
   components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      Listbox,
      ListboxButton,
      ListboxLabel,
      ListboxOption,
      ListboxOptions,
      DotsHorizontalIcon,
      ChevronDownIcon,
      EmptyState,
      PlusIcon,
      TrashIcon,
      NewDepositProductModal,
      askForDepositProductDelete
  },
   data() {
      return {
        queryLoading: false,
        dbListener: null,
        depositProducts: [],
        newDepositProduct: false,
        modalDataDepositProduct: {
            headerText: "Usuwanie produktu podlegającego kaucji",
            bodyText: "Czy na pewno chcesz usunąć wybrany produkt podlegający kaucji? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteDepositProduct: false,
        deleteDepositProductId: null,
      }
   },
   created() {
      if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
        {
        setTimeout(() => {
          if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
          {
            this.$router.push("/")
          }
          this.getAllDepositProducts()
        }, 500);
        }else{
          this.getAllDepositProducts()
        }
   },
   beforeUnmount() {
      if(this.dbListener !== null){
         this.dbListener();
      }
   },
   methods: {
      getAllDepositProducts()
      {
         this.dbListener = db.collection("depositProducts").doc('depositProducts')
         .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            this.depositProducts = []
            let depositProducts = querySnapshot.data().depositProducts
            this.depositProducts = depositProducts
            this.queryLoading = false;
         });
      },
      async deleteDepositProductFromDb(){
        this.deleteDepositProduct = false
        await db.collection("depositProducts").doc('depositProducts').update({
            'depositProducts': firebase.firestore.FieldValue.arrayRemove(this.deleteDepositProductId)
        })
        this.deleteDepositProductId = null
      },
   }
}
</script>

<style>

</style>