<template>
    <div class="flex justify-between items-center mb-4">
        <span class="text-xl font-semibold text-gray-800">Zarządzaj produktami zestawowymi</span>
        <div @click.prevent="newSetProductModal = true" class="cursor-pointer transition ease-in-out duration-200 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj nowy produkt</div>
    </div>
    <div class="relative">
    <Transition name="fade">
      <div>
            <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <li v-for="(setProduct, index) in setProducts" :key="index" class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
            <div class="flex flex-1 flex-col p-8">
                <img class="mx-auto h-32 w-32 flex-shrink-0 rounded-full object-cover object-center" :src="setProduct.imageUrl" alt="" />
                <h3 class="mt-6 text-sm font-medium text-gray-900">{{ setProduct.setProductName }}</h3>
            </div>
            <div>
                <div class="-mt-px flex divide-x divide-gray-200">
                <div class="flex w-0 flex-1">
                    <div @click.prevent="editSetProductModal = true, editSetProduct = setProduct" class="transition ease-in-out duration-200 cursor-pointer hover:opacity-50 relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-blue-400">
                    <PencilAltIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                    Edycja
                    </div>
                </div>
                <div class="flex w-0 flex-1">
                    <div @click.prevent="deleteSetProduct = true ,deleteSetProductId = setProduct" class="transition ease-in-out duration-200 cursor-pointer hover:opacity-50 relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-red-900">
                    <TrashIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    Usuń
                    </div>
                </div>
                </div>
            </div>
            </li>
        </ul>
      </div>
    </Transition>
  </div>
  <EmptyState class="mt-4" v-if="setProducts.length === 0" />
  <NewSetProduct v-if="newSetProductModal" @accept="newSetProductUploaded()" @closeModal="newSetProductModal = false"></NewSetProduct>
  <EditSetProduct v-if="editSetProductModal" @accept="editSetProductSuccess()" :editSetProduct="editSetProduct" @closeModal="editSetProductModal = false, editSetProduct = null"></EditSetProduct>
  <askForSetProductDelete v-if="deleteSetProduct" @accept="deleteSetProductFromDb" :modalData="modalSetProduct" @closeModal="deleteSetProduct = false, deleteSetProductId = null"></askForSetProductDelete>
</template>

<script>
import {db, firebase, storage} from "@/firebase/gfbconf.js"
import { Menu, MenuButton, MenuItem, MenuItems, Listbox,ListboxButton,ListboxLabel,ListboxOption,ListboxOptions, } from '@headlessui/vue'
import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { ChevronDownIcon, PlusIcon, TrashIcon,PencilAltIcon } from '@heroicons/vue/outline'
import EmptyState from '@/components/EmptyState.vue';
import NewSetProduct from '@/components/NewSetProduct.vue';
import EditSetProduct from '@/components/EditSetProduct.vue';
import askForSetProductDelete from '@/components/AlertModal.vue';
import moment from 'moment';

export default {
   components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      Listbox,
      ListboxButton,
      ListboxLabel,
      ListboxOption,
      ListboxOptions,
      DotsHorizontalIcon,
      ChevronDownIcon,
      EmptyState,
      PlusIcon,
      TrashIcon,
      NewSetProduct,
      EditSetProduct,
      askForSetProductDelete,
      PencilAltIcon
  },
   data() {
      return {
        queryLoading: false,
        dbListener: null,
        setProducts: [],
        newSetProductModal: false,
        editSetProductModal: false,
        editSetProduct: null,
        modalSetProduct: {
            headerText: "Usuwanie produktu zestawowego",
            bodyText: "Czy na pewno chcesz usunąć wybrany produkt zestawowy? Usunięcie spowoduje wygaśnięcie zdjęcia w utworzonych zamówieniach oraz jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteSetProduct: false,
        deleteSetProductId: null,
      }
   },
   created() {
      if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
        {
        setTimeout(() => {
          if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
          {
            this.$router.push("/")
          }
          this.getAllSetProducts()
        }, 500);
        }else{
          this.getAllSetProducts()
        }
   },
   beforeUnmount() {
      if(this.dbListener !== null){
         this.dbListener();
      }
   },
   methods: {
      async removeImage(ref){
        const mainImageRef = storage.ref().child(ref);
        try {
          await mainImageRef.delete();
        } catch (error) {
          console.log(error)
        }
      },
      newSetProductUploaded(){
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona sukcesem!",
          subheader: "Produkt został dodany do bazy danych.",
          success: true
        });
      },
      editSetProductSuccess(){
        this.editSetProduct = null
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona sukcesem!",
          subheader: "Produkt został zmodyfikowany we wszystkich produktach.",
          success: true
        });
      },
      getAllSetProducts()
      {
         this.dbListener = db.collection("setProducts").doc('setProducts')
         .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            this.setProducts = []
            let setProducts = querySnapshot.data().setProducts
            this.setProducts = setProducts
            this.queryLoading = false;
         });
      },
      async removeFromExistSets(setProductData){
        let categoriesDocs = await db.collection("ProductCategories").get()
        for (let i = 0; i < categoriesDocs.docs.length; i++) {
          const element = categoriesDocs.docs[i].data();
          for (let x = 0; x < Object.keys(element.products).length; x++) {
            const productId = Object.keys(element.products)[x]
            const product = element.products[productId];
            product.createdDate = moment.unix(product.createdDate.seconds).toDate()
            if(product.availableSetProducts !== undefined){
              for (let y = 0; y < product.availableSetProducts.length; y++) {
                const availableSetProduct = product.availableSetProducts[y];
                availableSetProduct.createdDate = moment.unix(availableSetProduct.createdDate).toDate()
                if(availableSetProduct.setProductName === setProductData.setProductName && availableSetProduct.imageRef === setProductData.imageRef){
                  product.availableSetProducts.splice(y,1)
                  let categoryOfProductId = categoriesDocs.docs[i].id
                  await db.collection("ProductCategories").doc(categoryOfProductId).update({
                    [`products.${productId}`]: product,
                  })
                }
              }
            }
          }
        }
      },
      async deleteSetProductFromDb(){
        this.deleteSetProduct = false
        this.removeFromExistSets(this.deleteSetProductId)
        this.removeImage(this.deleteSetProductId.imageRef)
        await db.collection("setProducts").doc('setProducts').update({
            'setProducts': firebase.firestore.FieldValue.arrayRemove(this.deleteSetProductId)
        })
        this.deleteSetProductId = null
      },
   }
}
</script>

<style>

</style>