<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div :class="['bg-indigo-100']" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                <CubeTransparentIcon :class="['text-indigo-600']" class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full sm:mr-4">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Dodawanie nowego produktu zestawowego</DialogTitle>
                <div class="mt-2">
                  <!-- <p class="text-sm text-gray-500">
                    <input type="text" name="categoryName" id="categoryName" v-model="this.category.categoryName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa kategorii" />
                    <span v-if="this.error" class="text-xs text-red-500">Nazwa kategorii nie może być pusta</span>
                  </p> -->
                  <LoadingSpinnerHub v-if="loader"/>
                  <div v-if="!loader" class="border-t border-gray-100">
                    <dl class="divide-y divide-gray-100">
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Nazwa produktu:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="setProductName" class="sr-only">Nazwa produktu</label>
                            <input type="text" name="setProductName" id="setProductName" v-model="this.setProduct.setProductName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa produktu" />
                          </div>
                          <p v-if="this.errors.setProductName" class="text-xs text-red-500 mt-0.5 ml-1">Podaj nazwę produktu.</p>
                        </dd>
                      </div>
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Zdjęcie:</dt>
                        <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0 sm:col-span-3" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                    </div>
                                    
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p v-if="this.errors.image" class="text-xs text-red-500 mt-0.5 ml-1">Musisz wybrać zdjęcie.</p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!loader" class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" :class="['bg-indigo-600 text-base font-medium hover:bg-indigo-700 focus:ring-indigo-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm' ]" @click="uploadNewImageToGallery">Dodaj nowy produkt</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {db, storage, firebase} from "@/firebase/gfbconf.js";
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CubeTransparentIcon } from '@heroicons/vue/outline'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
export default {
  components: {
    LoadingSpinnerHub,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CubeTransparentIcon,
  },
  props: [],
  data() {
    return {
        loader: false,
        fileToUpload: null,
        imgPreviewSrc: "",
        fileLimitSize: 2097152,
        setProduct: {
          createdDate: null,
          imageRef: null,
          imageUrl: "",
          setProductName: "",
        },
        errors: {
          setProductName: false,
        }
    }
  },
  created(){
  },
  methods: {
    filenameGenerator(length)
            {
                let result           = `setProd_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
    dropFile(e)
    {
        if(e.dataTransfer.files.length>0)
        {
            if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
            {
                if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                {
                    this.fileToUpload = e.dataTransfer.files[0]
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(e.dataTransfer.files[0]);
                    
                    fileReader.addEventListener("load", () =>
                    {
                        this.imgPreviewSrc = fileReader.result;
                    })
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zdjęcie waży powyżej 2MB",
                        subheader: `Plik jest zbyt ciężki.`,
                        success: false
                    }); 
                }
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Plik nie jest zdjęciem.",
                    subheader: `Plik powinien być zdjęciem.`,
                    success: false
                });   
            }
        }
        
    },
    setMainImage()
    {
        if(this.$refs.mainImgUploader.files.length>0)
        {
            if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
            {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                fileReader.addEventListener("load", () =>
                {
                    this.imgPreviewSrc = fileReader.result;
                })
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Zdjęcie waży powyżej 2MB",
                    subheader: `Plik jest zbyt ciężki.`,
                    success: false
                });                    
            }
        }
    },
    triggerImgClick()
    {
        this.$refs.mainImgUploader.click();
    },
    clearImg()
    {
        this.imgPreviewSrc = '';
        this.$refs.mainImgUploader.value = "";
        this.fileToUpload = null
    },
    generateId(){
        let uuid = "", i, random;
        for (i = 0; i < 32; i++) {
          random = Math.random() * 16 | 0;
      
          if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += "-"
          }
          uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
        }
        return uuid;
    },
    errorCheck(){
      this.errors.setProductName = false
      this.errors.image = false
      let errors = []
      if(this.setProduct.setProductName.length < 1){
        this.errors.setProductName = true
        errors.push('setProductName')
      }
      if(this.imgPreviewSrc.length < 1){
        this.errors.image = true
        errors.push('image')
      }
      return errors
    },
    async uploadNewImageToGallery(){
        let errors = this.errorCheck()
        if(errors.length === 0){
          this.loader = true
          if(this.fileToUpload === null){
            this.fileToUpload = this.$refs.mainImgUploader.files[0]
          }
          if(this.fileToUpload !== null)
          {
              const storageRef = storage.ref();
              const filename = this.filenameGenerator(10);
              const path = `setProducts/${filename}`;
              const imageRef = storageRef.child(path);
              await imageRef.put(this.fileToUpload);
              const fileUrl = await imageRef.getDownloadURL();
              this.setProduct.imageUrl = fileUrl;
              this.setProduct.imageRef = path;
          }
          let id = this.generateId()
          this.setProduct.createdDate = new Date()
          await db.collection("setProducts").doc('setProducts').update({
              'setProducts': firebase.firestore.FieldValue.arrayUnion(this.setProduct)
          })
          this.loader = false
          this.emitAccept()
        }
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    }
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
