<template>
  <TransitionRoot as="template" :show="open && managedUsr !== undefined && managedUsr !== null">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-500 to-indigo-800 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Zarządzaj użytkownikiem</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zmień dane użytkownika {{`${managedUsr.name} ${managedUsr.surname}`}}. </p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Dane logowania </div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">E-mail <span></span></label>
                            <div class="mt-1 text-gray-500">
                              <span>{{managedUsr.contact.email}}</span>
                            </div>
                        </div>
                        <div class="select-none mt-2">
                            <label for="email" class="block text-sm font-medium text-gray-700">Hasło<span></span></label>
                            <div class="mt-1 text-gray-500">
                              <button type="button" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click.prevent="resetPwd">Zresetuj hasło</button>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Status konta </div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Aktywny <span></span></label>
                              <Switch v-model="userUpdate.active" :class="[userUpdate.active ? 'bg-indigo-600' : 'bg-gray-200', 'mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                <span class="sr-only">Use setting</span>
                                <span :class="[userUpdate.active ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                                  <span :class="[userUpdate.active ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  </span>
                                  <span :class="[userUpdate.active ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                    </svg>
                                  </span>
                                </span>
                              </Switch>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Informacje</div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Imię*</label>
                            <div class="mt-1">
                                <input type="text" v-model="userUpdate.name" maxlength="20"  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Nazwisko*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="userUpdate.surname" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Kowalski" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.surname"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Telefon komórkowy*</label>
                            <div class="mt-1">
                                <input v-model="userUpdate.phone" maxlength="20" type="text" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Numer telefonu z kierunkowym" />
                            </div>
                            <!-- <span class="text-xs select-none text-red-500" v-if="errors.phone"> Poprawny format to +XXXXXXXXXXX.</span> -->
                            <span class="text-xs select-none text-red-500" v-if="errors.phone"> Uzupełnij numer telefonu.</span>
                        </div>

                        <div class="mt-4">
                        <label for="email" class="block text-sm font-medium text-gray-700">Zdjęcie</label>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0 sm:col-span-3" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                    </div>
                                    
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p v-if="this.errors.image" class="text-xs text-red-500 mt-0.5 ml-1">Musisz wybrać zdjęcie.</p>
                        </dd>
                      </div>
                    </div>

                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium text-red-500">Usuń użytkownika</div>
                        <div class="flex items-center mt-1 mb-4">
                          <input v-model="deleteUserOnSave" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500">
                          <span class="text-xs ml-2 select-none text-">Chcę usunąć to konto - wiem co robię.</span>
                        </div>
                    </div>
                  </div>

                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <button @click.prevent="emitClose" class="transition-all duration-300 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" v-if="!loading">Zamknij</button>
                  <button v-if="!deleteUserOnSave && !loading" @click.prevent="validateUserDataBeforeUpdate" type="button" class="transition-all duration-300 ml-2 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Aktualizuj
                    <RefreshIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                  <button type="button" @click.prevent="deleteUser" v-if="deleteUserOnSave && !loading" class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń konto</button>
                  <span class="text-xs text-gray-500" v-if="loading">Zapisywanie..</span>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue';
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon, RefreshIcon } from '@heroicons/vue/solid';
    import axios from 'axios';
    import {storage} from "@/firebase/gfbconf.js";
    export default {
        name: "ClientsModal",
        components: {
          Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, QuestionMarkCircleIcon, RefreshIcon, Switch, SwitchDescription, SwitchGroup, SwitchLabel
        },
        props: ['managedUsr'],
        data: function () {
            return {
              enabled: false,
              loading: false,
              deleteUserOnSave: false,
              open: false,
              userUpdate: {
                name: "",
                surname: "",
                phone: "",
              },
              errors: {
                name: false,
                surname: false,
                phone: false,
              },      
              fileToUpload: null,
              imgPreviewSrc: "",
              fileLimitSize: 2097152,
              image: {
                createdDate: null,
                imageRef: null,
                imageUrl: "",
              },
            }
        },
        created()
        {
          this.createUserUpdateObj()
        },
        methods: {
          filenameGenerator(length)
            {
                let result           = `user_${this.userUpdate.name}_${this.userUpdate.name}_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
          dropFile(e)
          {
              if(e.dataTransfer.files.length>0)
              {
                  if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                  {
                      if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                      {
                          this.fileToUpload = e.dataTransfer.files[0]
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(e.dataTransfer.files[0]);
                          
                          fileReader.addEventListener("load", () =>
                          {
                              this.imgPreviewSrc = fileReader.result;
                          })
                      }
                      else
                      {
                          this.$store.commit('setNotification',{
                              show: true,
                              head: "Zdjęcie waży powyżej 2MB",
                              subheader: `Plik jest zbyt ciężki.`,
                              success: false
                          }); 
                      }
                  }
                  else
                  {
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Plik nie jest zdjęciem.",
                          subheader: `Plik powinien być zdjęciem.`,
                          success: false
                      });   
                  }
              }
              
          },
          setMainImage()
          {
              if(this.$refs.mainImgUploader.files.length>0)
              {
                  if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                  {
                      const fileReader = new FileReader();
                      fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                      fileReader.addEventListener("load", () =>
                      {
                          this.imgPreviewSrc = fileReader.result;
                      })
                  }
                  else
                  {
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Zdjęcie waży powyżej 2MB",
                          subheader: `Plik jest zbyt ciężki.`,
                          success: false
                      });                    
                  }
              }
          },
          triggerImgClick()
          {
              this.$refs.mainImgUploader.click();
          },
          clearImg()
          {
              this.imgPreviewSrc = '';
              this.$refs.mainImgUploader.value = "";
              this.fileToUpload = null
          },
          async resetPwd()
          {
            const res = await axios.post(`https://europe-central2-wbtankrestaurant.cloudfunctions.net/api/resetPasswordViaLink`, {
              email: this.managedUsr.contact.email
            })
            if(res.data.success)
            {
              window.open(res.data.link, '_blank').focus();
            }
          },
          createUserUpdateObj()
          {
            this.userUpdate.name = this.managedUsr.name.toString();
            this.userUpdate.surname = this.managedUsr.surname.toString();
            this.userUpdate.phone = this.managedUsr.contact.phone.toString();
            this.userUpdate.active = this.managedUsr.active;
            this.userUpdate.userImage = this.managedUsr.userImage;
            if(this.userUpdate.userImage !== null){
              this.imgPreviewSrc = this.userUpdate.userImage.imageUrl
            }
          },
          async deleteUser()
          {
            if(this.managedUsr.id === undefined || this.managedUsr.id === null) return;
            if(this.managedUsr.id.length === 0) return;
            this.loading = true;
            try{
              const res = await axios.post(`https://europe-central2-wbtankrestaurant.cloudfunctions.net/api/deleteUser`, {
                id: this.managedUsr.id,
              });
              if(res.data.success)
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: "Użytkownik został usunięty.",
                  success: true
                });
                this.loading = false;                
              }
              else
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Użytkownik nie został usunięty.",
                  success: false
                });
                this.loading = false; 
              }       
            }
            catch(error)
            {
                this.$emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Użytkownik nie został usunięty.",
                  success: false
                });
                this.loading = false;
            }
          },
          async removeImgFromStorage(imgData){
            const mainImageRef = storage.ref().child(imgData.imageRef);
            try {
              await mainImageRef.delete();
            } catch (error) {
              console.log(error)
            }
          },
          async uploadNewImageToStorage(){
              if(this.fileToUpload === null){
                if(this.$refs.mainImgUploader.files[0] !== undefined){
                  this.fileToUpload = this.$refs.mainImgUploader.files[0]
                }
              }
              if(this.fileToUpload !== null)
              {
                  const storageRef = storage.ref();
                  const filename = this.filenameGenerator(10);
                  const path = `usersImages/${filename}`;
                  const imageRef = storageRef.child(path);
                  await imageRef.put(this.fileToUpload);
                  const fileUrl = await imageRef.getDownloadURL();
                  this.image.imageUrl = fileUrl;
                  this.image.imageRef = path;
              }
              this.image.createdDate = new Date()
        },
          async updateUser()
          {
            if(this.managedUsr.id === undefined || this.managedUsr.id === null) return;
            if(this.managedUsr.id.length === 0) return;
            this.loading = true;
            try{
              if(this.imgPreviewSrc.length === 0){
                await this.removeImgFromStorage(this.userUpdate.userImage)
                this.userUpdate.userImage = null
              }else{  
                if(this.managedUsr.userImage !== null){
                  if(this.managedUsr.userImage.imageUrl !== this.imgPreviewSrc){
                    await this.removeImgFromStorage(this.userUpdate.userImage)
                    await this.uploadNewImageToStorage()
                    this.userUpdate.userImage = this.image
                  }
                }else{
                  if(this.imgPreviewSrc.length > 0){
                    await this.uploadNewImageToStorage()
                    this.userUpdate.userImage = this.image
                  }
                }
              }
              const res = await axios.post(`https://europe-central2-wbtankrestaurant.cloudfunctions.net/api/updateUser`, {
                loggedUser: this.$store.state.userData,
                update: this.userUpdate,
                id: this.managedUsr.id,
              })
              if(res.data.success)
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: "Użytkownik został zmodyfikowany.",
                  success: true
                });
                this.loading = false;
              }
              else
              {
                this.emitClose();
                this.loading = false;
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Coś poszło nie tak..",
                  success: false
                });
              }
            }
            catch(error)
            {
              console.log(error);
              this.emitClose();
              this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: "Coś poszło nie tak..",
                success: false
              });
              this.loading = false;
            }
          },
          clearErrors()
          {
            this.errors.name = false;
            this.errors.surname = false;
            this.errors.phone = false;
          },
          userValidator()
          {
            let errorsAmount = 0;
            this.clearErrors();
            if(this.userUpdate.name.length === 0)
            {
              errorsAmount++;
              this.errors.name = true;
            }
            if(this.userUpdate.surname.length === 0)
            {
              errorsAmount++;
              this.errors.surname = true;
            }
            // if(/^\+?[1-9][0-9]{7,14}$/.test(this.userUpdate.phone) === false)
            // {
            //   errorsAmount++;
            //   this.errors.phone = true;
            // }
            if(this.userUpdate.phone.length === 0)
            {
              errorsAmount++;
              this.errors.phone = true;
            }

            return errorsAmount;

          },
          validateUserDataBeforeUpdate()
          {
            this.clearErrors();
            if(this.userValidator() > 0) return;
            this.updateUser();    
          },
          emitClose()
          {
            this.clearErrors();
            this.$emit("closeClientModal");
          },
          // Create new client
        },
        computed:{

        }
    };
</script>