<template>
  <div class="text-xl font-semibold text-gray-800 mb-2">
    Zarządzaj produktami dodatkowymi
  </div>
  <ul role="list" class="grid grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-3 xl:gap-x-4">
    <li @click.prevent="newAdditionalProduct = true" v-tooltip="`Dodaj nowy produkt`" class="flex items-center justify-between cursor-pointer transition ease-in-out duration-100 hover:opacity-60 overflow-hidden rounded-xl border border-gray-200">
        <div class="flex w-full bg-white items-center py-6 px-4 justify-between">
            <div class="w-10/12">
                <div class="truncate text-sm font-medium text-green-500">Dodaj nowy produkt</div>
            </div>
            <div>
                <PlusIcon class="h-5 w-5 text-green-600 " />
            </div>
        </div>
    </li>
    <li v-for="(product,ind) in additionalProducts" :key="ind" class="transition ease-in-out duration-100 overflow-hidden rounded-xl border border-gray-200">
      <div class="flex items-center gap-x-4 bg-gray-100 py-6 px-4">
        <div class="flex w-full items-center justify-between">
            <div class="w-10/12">
                <div class="truncate text-sm font-medium text-gray-700" >{{product}}</div>
            </div>
            <div>
                <TrashIcon @click.prevent="deleteAdditionalProduct = true ,deleteAdditionalProductId = product" class="transition ease-in-out duration-200 h-5 w-5 text-red-500 hover:text-red-700 cursor-pointer" />
            </div>
        </div>
      </div>
    </li>
  </ul>
  <EmptyState class="mt-4" v-if="additionalProducts.length === 0" />
  <NewAdditionalProductModal v-if="newAdditionalProduct" @closeModal="newAdditionalProduct = false"></NewAdditionalProductModal>
  <askForAdditionalProductDelete v-if="deleteAdditionalProduct" @accept="deleteAdditionalProductFromDb" :modalData="modalDataAdditionalProduct" @closeModal="deleteAdditionalProduct = false, deleteAdditionalProductId = null"></askForAdditionalProductDelete>
</template>

<script>
import {db, firebase} from "@/firebase/gfbconf.js"
import { Menu, MenuButton, MenuItem, MenuItems, Listbox,ListboxButton,ListboxLabel,ListboxOption,ListboxOptions, } from '@headlessui/vue'
import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { ChevronDownIcon, PlusIcon, TrashIcon } from '@heroicons/vue/outline'
import EmptyState from '@/components/EmptyState.vue';
import NewAdditionalProductModal from '@/components/NewAdditionalProductModal.vue';
import askForAdditionalProductDelete from '@/components/AlertModal.vue';

export default {
   components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      Listbox,
      ListboxButton,
      ListboxLabel,
      ListboxOption,
      ListboxOptions,
      DotsHorizontalIcon,
      ChevronDownIcon,
      EmptyState,
      PlusIcon,
      TrashIcon,
      NewAdditionalProductModal,
      askForAdditionalProductDelete
  },
   data() {
      return {
        queryLoading: false,
        dbListener: null,
        additionalProducts: [],
        newAdditionalProduct: false,
        modalDataAdditionalProduct: {
            headerText: "Usuwanie produktu dodatkowego",
            bodyText: "Czy na pewno chcesz usunąć wybrany produkt dodatkowy? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteAdditionalProduct: false,
        deleteAdditionalProductId: null,
      }
   },
   created() {
      if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
        {
        setTimeout(() => {
          if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
          {
            this.$router.push("/")
          }
          this.getAllAdditionalProducts()
        }, 500);
        }else{
          this.getAllAdditionalProducts()
        }
   },
   beforeUnmount() {
      if(this.dbListener !== null){
         this.dbListener();
      }
   },
   methods: {
      getAllAdditionalProducts()
      {
         this.dbListener = db.collection("additionalProducts").doc('additionalProducts')
         .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            this.additionalProducts = []
            let additionalProducts = querySnapshot.data().additionalProducts
            this.additionalProducts = additionalProducts
            this.queryLoading = false;
         });
      },
      async deleteAdditionalProductFromDb(){
        this.deleteAdditionalProduct = false
        await db.collection("additionalProducts").doc('additionalProducts').update({
            'additionalProducts': firebase.firestore.FieldValue.arrayRemove(this.deleteAdditionalProductId)
        })
        this.deleteAdditionalProductId = null
      },
   }
}
</script>

<style>

</style>