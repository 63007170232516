<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
         <div class="inline-block min-w-full py-2 align-middle px-1 lg:px-2">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
               <table class="min-w-full divide-y divide-gray-300">
               <thead class="bg-gray-50">
                  <tr>
                     <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Aktywny</th>
                     <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Imię Nazwisko</th>
                     <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kontakt</th>
                     <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                     <span class="sr-only">Edit</span>
                     </th>
                  </tr>
               </thead>
               <tbody v-if="$store.state.authIsReady" class="divide-y divide-gray-200 bg-white">
                  <tr v-for="user in userList" :key="user.id">
                     <td class="select-none whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        <div class="flex items-center">
                           <span :class="['inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-xs font-medium', user.active ? 'text-green-500 bg-green-100' : 'text-red-500 bg-red-100']">
                              <svg class="h-1.5 w-1.5 fill-current" viewBox="0 0 6 6" aria-hidden="true">
                                 <circle cx="3" cy="3" r="3" />
                              </svg>
                              <span class="text-gray-900">
                                 {{ user.active ? 'Aktywny' : 'Nieaktywny'}}
                              </span>
                           </span>
                           <span v-if="user.admin !== undefined && user.admin === true" :class="['ml-2 inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-xs font-medium', 'text-red-600 bg-red-200']">
                              <span>
                                 Admin
                              </span>
                           </span>
                        </div>
                     </td>
                     <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{user.name + ' ' + user.surname}}</td>
                     <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span v-tooltip="`Numer telefonu: ${ user.contact.phone }`">{{ user.contact.email }}</span>
                     </td>
                     <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none">
                           <button v-if="$store.state.userData.admin !== undefined && $store.state.userData.admin === true" type="button" @click.prevent="showManageModal = true, pickedUsr = user" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zarządzaj</button>
                     </td>
                  </tr>
               </tbody>
               </table>
            </div>
         </div>
         </div>
      </div>
   </div>
   <ModalManageUser :show="showManageModal" :managedUsr="pickedUsr" @closeClientModal="closeClientModal" v-if="pickedUsr !== null"></ModalManageUser>
</template>

<script>
import {db} from "@/firebase/gfbconf.js"
import ModalManageUser from '../components/ModalManageUser.vue';

export default {
   components: {
      ModalManageUser
   },
   data() {
      return {
         showManageModal: false,
         pickedUsr: null,
         queryLoading: false,
         queryLimitReached: false,
         dbListener: null,
         lastLoaded: null,
         userList: [],
         queryLimit: 25
      }
   },
   created() {
      // if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
      // {
      //    this.$router.push("/")
      // }
      this.getAllUsers()
   },
   methods: {
      getAllUsers()
      {
         this.dbListener = db.collection("UsersData").orderBy("meta.created.unix",'desc')
         .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            this.userList = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
               this.queryLimitReached = true;
            }
            else
            {
               this.queryLimitReached = false;
            }
            this.userList = []
            querySnapshot.forEach((doc) => {
               let user = doc.data()
               user.id = doc.id
               this.userList.push(user)
            });
            this.queryLoading = false;
         });
      },
      closeClientModal()
      {
         this.showManageModal = false;

         // Allow transition to end
         setTimeout(()=>
         {
            this.pickedUsr = null
         },300)
      },
   }
}
</script>

<style>

</style>