<template>
    <div class="flex justify-between items-center mb-4">
        <span class="text-xl font-semibold text-gray-800">Zarządzaj zdjęciami w galerii</span>
        <div @click.prevent="newImageInGalery = true" class="cursor-pointer transition ease-in-out duration-200 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj nowe zdjęcie</div>
    </div>
    <div class="relative">
    <Transition name="fade">
      <div class="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="flex flex-col overflow-hidden rounded-lg mb-4 shadow-lg" v-for="(image, index) in images" :key="index">
          <!-- POINTER EVENTS DIV UNDER CALSSES: hover:opacity-60 cursor-pointer transition ease-in-out duration-200 -->
          <div class="">
          <div class="flex-shrink-1 relative">
            <img class="h-96 w-full object-cover" :src="image.imageUrl"/>
            <div class="absolute bottom-0 left-0 right-0 px-4 py-2 bg-black opacity-60 shadow-2xl select-none">
              <div class="text-white flex justify-between items-center">
                <TrashIcon @click.prevent="deleteImage = true ,deleteImageId = image" class="w-4 h-4 mr-2 text-red-600 hover:text-red-700 cursor-pointer transition ease-in-out duration-200" />
                <span class="text-white float-right">{{ image.imageTitle }} </span>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <EmptyState class="mt-4" v-if="images.length === 0" />
  <NewImageInGalleryModal v-if="newImageInGalery" @accept="newImageUplaoded()" @closeModal="newImageInGalery = false"></NewImageInGalleryModal>
  <askForImageDelete v-if="deleteImage" @accept="deleteImageInGalleryFromDb" :modalData="modalImageProduct" @closeModal="deleteImage = false, deleteImageId = null"></askForImageDelete>
</template>

<script>
import {db, firebase, storage} from "@/firebase/gfbconf.js"
import { Menu, MenuButton, MenuItem, MenuItems, Listbox,ListboxButton,ListboxLabel,ListboxOption,ListboxOptions, } from '@headlessui/vue'
import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { ChevronDownIcon, PlusIcon, TrashIcon } from '@heroicons/vue/outline'
import EmptyState from '@/components/EmptyState.vue';
import NewImageInGalleryModal from '@/components/NewImageInGalleryModal.vue';
import askForImageDelete from '@/components/AlertModal.vue';

export default {
   components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      Listbox,
      ListboxButton,
      ListboxLabel,
      ListboxOption,
      ListboxOptions,
      DotsHorizontalIcon,
      ChevronDownIcon,
      EmptyState,
      PlusIcon,
      TrashIcon,
      NewImageInGalleryModal,
      askForImageDelete
  },
   data() {
      return {
        queryLoading: false,
        dbListener: null,
        images: [],
        newImageInGalery: false,
        modalImageProduct: {
            headerText: "Usuwanie zdjęcia z galerii",
            bodyText: "Czy na pewno chcesz usunąć wybrane zdjęcie z galerii? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteImage: false,
        deleteImageId: null,
      }
   },
   created() {
      if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
        {
        setTimeout(() => {
          if(!this.$store.state.authIsReady && this.$store.state.userData === null && this.$store.state.user === null)
          {
            this.$router.push("/")
          }
          this.getAllImages()
        }, 500);
        }else{
          this.getAllImages()
        }
   },
   beforeUnmount() {
      if(this.dbListener !== null){
         this.dbListener();
      }
   },
   methods: {
      async removeImage(ref){
        const mainImageRef = storage.ref().child(ref);
        try {
          await mainImageRef.delete();
        } catch (error) {
          console.log(error)
        }
      },
      newImageUplaoded(){
        this.$store.commit('setNotification',{
          show: true,
          head: "Akcja zakończona sukcesem!",
          subheader: "Zdjęcie zostało wgrane.",
          success: true
        });
      },
      getAllImages()
      {
         this.dbListener = db.collection("imageGallery").doc('imageGallery')
         .onSnapshot((querySnapshot) => {
            this.queryLoading = true;
            this.images = []
            let images = querySnapshot.data().imageGallery
            this.images = images
            this.queryLoading = false;
         });
      },
      async deleteImageInGalleryFromDb(){
        this.deleteImage = false
        this.removeImage(this.deleteImageId.imageRef)
        await db.collection("imageGallery").doc('imageGallery').update({
            'imageGallery': firebase.firestore.FieldValue.arrayRemove(this.deleteImageId)
        })
        this.deleteImageId = null
      },
   }
}
</script>

<style>

</style>